import React from "react";
import Dropzone from "react-dropzone";
import { Alert, Button, Card, CardBody, Col, FormFeedback, FormGroup, Input, Label, Row, UncontrolledAlert } from "reactstrap";
import { getUrlBackEnd, getUser } from "../../data/Auth";
import { isNullOrEmptySpace } from "../../framework/AstroNetUtility";
import { ExecuteDataSource } from "../../framework/AstroNetREST";
import Papa from "papaparse";
import ContactList from "./ContactList";

class ContactImport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileNames: [],
            files: [],
            jsonDataContacts: null,
            form: {
                whatsapp: {
                    label: "WhatsApp",
                    type: "select",
                    validation: {
                        isValid: true
                    }
                }
            },
            alert: {
                color: "danger",
                message: null
            }
        }
    }

    onChange(ev) {
        const newProgram = this.state.form;
        const { name, value } = ev.currentTarget;
        console.log(value)
        if (!isNullOrEmptySpace(newProgram[name].type) && newProgram[name].type.toUpperCase() == "select") {
            try {
                newProgram[name].value = parseInt(value);
            }
            catch {
                newProgram[name].value = value;
            }
        }
        else
            newProgram[name].value = value;

        newProgram[name] = this.validation(newProgram[name])
        this.setState({ form: newProgram })
    }
    validation = (obj) => {
        let typesDecInt = ["INT", "DECIMAL"]
        if (isNullOrEmptySpace(obj.validation) || isNullOrEmptySpace(obj.validation.regex)) {
            obj.validation.isValid = true
            return obj
        }

        if (!isNullOrEmptySpace(obj.type) && typesDecInt.includes(obj.type.toUpperCase()) && isNullOrEmptySpace(obj.value)) {
            obj.validation.isValid = false
            return obj
        }

        switch (obj.validation.regex.constructor) {
            case String:
                if (!isNullOrEmptySpace(obj.type) &&
                    obj.type.toUpperCase() == "SELECT" &&
                    obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = (!isNullOrEmptySpace(obj.value) && parseInt(obj.value) > 0)
                else if (!isNullOrEmptySpace(obj.type) &&
                    typesDecInt.includes(obj.type.toUpperCase()) &&
                    obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = (!isNullOrEmptySpace(obj.value) && parseInt(obj.value) > 0)
                else if (obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = !isNullOrEmptySpace(obj.value)

                break;
            case RegExp:
                obj.validation.isValid = isNullOrEmptySpace(obj.value) ? false : !isNullOrEmptySpace(obj.validation.regex.exec(obj.value))
                break;
            default:
                obj.validation.isValid = true
        }
        return obj
    }

    import() {
        let _this = this
        const { files } = this.state;
        const reader = new FileReader();
        const jsonData = []

        reader.onload = ({ target }) => {

            const papaParseConfigObj = {
                header: true,
                dynamicTyping: true
            }
            const renameKey = (obj, oldKey, newKey) => {
                obj[newKey] = obj[oldKey];
                delete obj[oldKey];
            }
            const arr = Papa.parse(target.result, papaParseConfigObj);
            for (const i in arr.data) {
                let obj = arr.data[i]
                let keys = Object.keys(obj)
                for (const x in keys) {
                    let key = keys[x]
                    if (!isNullOrEmptySpace(key))
                        renameKey(obj, key, key.toLocaleLowerCase().replace(/ /g, "_").replace(/-/g, ""))
                }
                let name = ""
                let email = ""
                let company = obj.company
                let category = obj.categories

                if (!isNullOrEmptySpace(obj.first_name))
                    name = obj.first_name
                if (!isNullOrEmptySpace(obj.middle_name))
                    name += ` ${obj.middle_name}`
                if (!isNullOrEmptySpace(obj.last_name))
                    name += ` ${obj.last_name}`

                if (!isNullOrEmptySpace(obj.email_address))
                    email = obj.email_address
                if (!isNullOrEmptySpace(obj.email_2_address))
                    email += `, ${obj.email_2_address}`
                if (!isNullOrEmptySpace(obj.email_3_address))
                    email += `, ${obj.email_3_address}`

                if (!isNullOrEmptySpace(obj.mobile_phone)) {
                    jsonData.push({
                        name: name,
                        company: company,
                        email: email,
                        phone: obj.mobile_phone,
                        category: category,
                    })
                }



                /*
                Se vc chegou aqui, temos mais esse campo o e campo NODES, caso vc esteja pensando como resolver,
                quero te dizer que tb to passando pelo mesmo(21/03/2023) então eu cheguei a pensar em importar duplicando o contato,
                mas o cliente pode ficar bravo, cheguei e pensar em deixar o cliente escolher na lista se deve ou não importar,
                pensei em ter um nó no meio do json só com os telefone, mas sei lá..... Bom chegou a hora, levate, proponha e resolva.
                Igor

                if (!isNullOrEmptySpace(obj.primary_phone)) {
                    jsonData.push({
                        name: `${name} 2`,
                        company: company,
                        email: email,
                        phone: obj.primary_phone,
                        category: category,
                    })
                }

                Aqui esta a lista de telefone.
                
                primary_phone
                home_phone_2
                home_phone
                company_main_phone
                business_phone
                business_phone_2
                assistant's_phone
                other_phone
                */
            }
 
            if (jsonData.length > 0)
                _this.validationContacts(jsonData)
        };
        reader.readAsText(files[0]);

    }

    validationContacts = (contacts) => {
        let _this = this
        var obj = {
            DataSourceMethod: 'POST',
            DataSourceUrl: getUrlBackEnd() + 'contact/validation',
            DataSourceData: contacts,
            DataSourceHeader: {
                'content-type': 'application/json',
                'Authorization': this.state.form.whatsapp.value
            }
        }

        ExecuteDataSource(obj).then(function (response) {
            let { alert } = _this.state
            alert.color = "success"
            alert.message = "Salvo com Sucesso"
            _this.setState({ jsonDataContacts: response })

        }).catch(function (error) {
            let { alert } = _this.state
            console.error(error)
            alert.message = error.response.data.Message
            alert.color = "danger"
            _this.setState({ alert })
        });
    }



    render() {
        const Alerts = () => {
            if (!isNullOrEmptySpace(this.state.alert.message))
                return (
                    <UncontrolledAlert color={this.state.alert.color}>
                        {this.state.alert.message}
                    </UncontrolledAlert>
                )
        }
        const handleDrop = (acceptedFiles) => {
            let list = acceptedFiles.map(file => file.name)
            this.setState({ fileNames: list, files: acceptedFiles })
            console.log("handleDrop")
        }

        const disabledButton = this.state.files.length == 0
        const disabledDropzone = isNullOrEmptySpace(this.state.form.whatsapp.value);

        if (isNullOrEmptySpace(this.state.jsonDataContacts))
            return (
                <Card>
                    <CardBody>
                        <h3>Importar Contatos CSV no formato <b>Outlook</b></h3>
                        {Alerts()}
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="whatsapp">
                                        {this.state.form.whatsapp.label}
                                    </Label >
                                    <Input invalid={!this.state.form.whatsapp.validation.isValid ? true : undefined}
                                        onChange={(e) => this.onChange(e)}
                                        name="whatsapp"
                                        value={this.state.form.whatsapp.value}
                                        validation={this.state.form.whatsapp.validation.regex}
                                        type="select">
                                        <option key={"0"} value={""}>
                                            Selecione
                                        </option>
                                        {
                                            getUser().whatsapps.map((x, i) => {
                                                return (
                                                    <option key={i} value={x.tokenWhatsApp}>
                                                        {x.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </Input>
                                    <FormFeedback invalid={!this.state.form.whatsapp.validation.isValid ? true : undefined}>
                                        {this.state.form.whatsapp.validation.error}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Dropzone
                                        disabled={disabledDropzone}
                                        onDrop={handleDrop}
                                        accept={{
                                            'text/csv': ['.csv']
                                        }}
                                    >
                                        {({
                                            getRootProps,
                                            getInputProps,
                                            isDragActive,
                                            isDragAccept,
                                            isDragReject
                                        }) => {

                                            const additionalClass = disabledDropzone ? "drop_disabled" : "";

                                            return (
                                                <div key={1} className="sectiondrop">
                                                    <div
                                                        {...getRootProps({
                                                            className: `dropzone ${additionalClass}`
                                                        })}
                                                    >
                                                        <input {...getInputProps()} accept=".csv" />
                                                        <h3 className="textdrop">Importar Arquivo CVS</h3>
                                                        <br />
                                                        <div className="archivodrop">
                                                            {this.state.fileNames.map((fileName, i) => (
                                                                <b><p key={i}>{fileName}</p></b>
                                                            ))}
                                                        </div>
                                                    </div>

                                                </div>

                                            );
                                        }}
                                    </Dropzone>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <FormGroup>
                                <Button disabled={disabledButton} color="deore" className={disabledButton ? "btn-secondary" : "btn-primary"} onClick={() => this.import()}>Enviar</Button>
                            </FormGroup>
                        </Row>
                    </CardBody>
                </Card>
            )
        else
            return (
                <Card>
                    <CardBody>
                        <h3>Importar Contatos CSV no formato <b>Outlook</b></h3>
                        <ContactList data={this.state.jsonDataContacts} />
                    </CardBody>
                </Card>
            )
    }
}
export default ContactImport;