import React from "react";
import { isNullOrEmptySpace } from "../../framework/AstroNetUtility";
import {
    Card, CardBody, Col, Row,
    FormGroup, Label, Button
} from "reactstrap"

class Error extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            error:{} 
        }
    }

    onClickBack() {
        window.location.href = "/"
    }

    componentWillMount(){
        let error = window.sessionStorage.getItem("error");
        if (!isNullOrEmptySpace(error)) {
            this.setState({ error: JSON.parse(error) })
        }
    }

    render() {
        if (isNullOrEmptySpace(this.state.error))
            return;
        var colprop = {
            xs: 6, //Extra large ≥1200px
            lg: 6, //Large ≥992px
            md: 6, //Medium ≥768px
            sm: 1, //Small ≥576px
        }
        return (
            <Card>
                <CardBody>
                    <h4><Label> Desculpe - Foi encontrado um comparatmento que não estava em nossos planos</Label></h4>
                    <br />
                    <Row>
                        <Col {...colprop} ><img src={require('../../../assets/emojiError.svg').default} alt="logo" style={{ width: "75%" }} /></Col>
                        <Col {...colprop}>
                            <Row><h2>{this.state.message}</h2></Row>
                            <FormGroup>
                                {JSON.stringify(this.state.info)}
                            </FormGroup>
                            <FormGroup>
                                {JSON.stringify(this.state.error)}
                            </FormGroup>
                            <FormGroup>
                                <h3>Response</h3>
                                {JSON.stringify(this.state.error.response)}
                            </FormGroup>
                            <FormGroup>
                                <h4><Label>Por favor nos ajude a melhor - nos envie esse erro</Label></h4>
                                <Row>
                                    <Button onClick={() => this.onClickBack()}>Voltar</Button>&nbsp;
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}

export default Error