import React from "react";
import System from "./components/layout/System";
import ClientHome from "./components/pages/client/ClientHome";
import Login from "./components/layout/Login";
import Forgot from "./components/pages/user/UserForgot";
import Error from "./components/pages/error/Error";
import UserHome from "./components/pages/user/UserHome"
import {
    Route,
    Routes
} from "react-router-dom";
import MessagesBatch from "./components/pages/message/MessagesBatch";
import ContactImport from "./components/pages/contact/ContactImport";

class RouterMaster extends React.Component {
    render() {
        return (
            <Routes>
                <Route path="/" element={<System />}>
                    <Route
                        path="client"
                        element={<ClientHome />}
                    />
                    <Route
                        path="user"
                        element={<UserHome />}
                    />
                    <Route
                        path="batch"
                        element={<MessagesBatch />}
                    />
                    <Route
                        path="contacts"
                        element={<ContactImport />}
                    />
                </Route>
                <Route
                    path="forgot/:key"
                    element={<Forgot />}
                />
                <Route
                    path="login"
                    element={<Login />}
                />
                <Route
                    path="error"
                    element={<Error />}
                />
            </Routes>
        )
    }
}
export default RouterMaster

