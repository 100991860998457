import React from "react"
import DataTable from "react-data-table-component";
import { paginationComponentOptions } from "../../framework/AstroNetUtility";
import { Button } from "reactstrap";
import StatusTalkHandle from "./StatusTalkHandle";
import { getUrlBackEnd, getUser } from "../../data/Auth";
import { ExecuteDataSource } from "../../framework/AstroNetREST";


class StatusTalkHome extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            new: false,
            list: [],
            form: {
                value: null,
                text: "",
                isActive: 1
            }
        }
    }

    componentWillMount() {

        this.getStatus()
    }


    getStatus = () => {
        let _this = this

        var obj = {
            DataSourceMethod: 'GET',
            DataSourceUrl: getUrlBackEnd() + 'whatsapp/status/get/' + this.props.tokenWhatsApp,
            DataSourceHeader: {
                'content-type': 'application/json',
                'Authorization': getUser().token
            }
        }

        ExecuteDataSource(obj).then(function (response) {
            _this.setState({ list: response, new: false })

        }).catch(function (error) {
            let { alert } = _this.state
            console.error(error)
            alert.message = error.response.data.Message
            alert.color = "danger"
            _this.setState({ alert, new: false })
        });
    }

    customStyles = () => {
        return {
            headRow: {
                style: {
                    border: 'none',

                },
            },
            headCells: {
                style: {
                    color: '#202124',
                    fontSize: '14px',

                },
            },
            rows: {
                highlightOnHoverStyle: {
                    backgroundColor: 'rgb(230, 244, 244)',
                    borderBottomColor: '#FFFFFF',
                    borderRadius: '25px',
                    outline: '1px solid #FFFFFF',

                },
            },
            pagination: {
                style: {
                    border: 'none',

                },
            }

        }
    }

    new = () => {
        this.setState({ new: true })
    }

    onClickRow = (row) => {
        const { form } = this.state
        form.value = row.value;
        form.text = row.text;
        form.isActive = row.isActive;

        this.setState({ new: true, form })
    }

    customStyles = () => {
        return {
            headRow: {
                style: {
                    border: 'none',

                },
            },
            headCells: {
                style: {
                    color: '#202124',
                    fontSize: '14px',

                },
            },
            rows: {
                highlightOnHoverStyle: {
                    backgroundColor: 'rgb(230, 244, 244)',
                    borderBottomColor: '#FFFFFF',
                    borderRadius: '25px',
                    outline: '1px solid #FFFFFF',

                },
            },
            pagination: {
                style: {
                    border: 'none',

                },
            }

        }
    }

    handle = (e) => {
        if (e.target.name == "cancel")
            this.setState({ new: false })
        else
            this.getStatus()
    }

    render = () => {
        const lister = () => {
            const columns = [
                {
                    name: 'Valor',
                    selector: row => row.value,
                    sortable: true,
                    style: {
                        color: 'rgba(0,0,0,.54)',
                    },
                },
                {
                    name: 'Texto',
                    selector: row => row.text,
                    sortable: true,
                    style: {
                        color: 'rgba(0,0,0,.54)',
                    },
                },
                {
                    name: 'Ativo',
                    sortable: true,
                    style: {
                        color: 'rgba(0,0,0,.54)',
                    },
                    cell: (row) =>
                        <>
                            <span>{row.isActive ? "Sim" : "Não"}</span>
                        </>
                },
            ];

            return (
                <DataTable
                    columns={columns}
                    data={this.state.list}
                    customStyles={this.customStyles}
                    pagination
                    paginationComponentOptions={paginationComponentOptions()}
                    noDataComponent="Não encontrado"
                    highlightOnHover
                    pointerOnHover
                    onRowClicked={(row) => this.onClickRow(row)}
                    actions={<Button color="deore" className="btn-primary" onClick={() => this.new()}>Novo</Button>}
                />
            )
        }
        if (!this.state.new)
            return (<>{lister()}</>)
        else
            return (<><StatusTalkHandle value={(e) => this.handle(e)} tokenWhatsApp={this.props.tokenWhatsApp} form={this.state.form} /></>)

    }
}

export default StatusTalkHome;