import React from "react"
import { Card, CardBody, Container, Row, Col, UncontrolledAlert, Form, FormGroup, Input, Label, FormFeedback, Button } from "reactstrap";
import { useParams } from "react-router-dom";
import { isNullOrEmptySpace } from "../../framework/AstroNetUtility";
import { getUrlBackEnd} from "../../data/Auth";
import { ExecuteDataSource } from "../../framework/AstroNetREST";

function withParams(Component) {
    return (props) => <Component {...props} params={useParams()} />;
}

class Forgot extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            form: {
                password: {
                    label: "Nova Senha",
                    validation: {
                        isValid: true,
                        error: "Senha deve ter mínimo de 8 caracteres, pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial",
                        regex: new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")
                    }
                },
                passwordReplay: {
                    label: "Repetir Senha",
                    validation: {
                        isValid: true,
                        error: "Senha Invalida",
                        regex: new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$")
                    }
                }
            },
            isInvalid: true,
            alert: {
                color: "danger",
                message: null
            }
        }
    }

    validation = (obj) => {
        let typesDecInt = ["INT", "DECIMAL"]
        if (isNullOrEmptySpace(obj.validation) || isNullOrEmptySpace(obj.validation.regex)) {
            obj.validation.isValid = true
            return obj
        }

        if (!isNullOrEmptySpace(obj.type) && typesDecInt.includes(obj.type.toUpperCase()) && isNullOrEmptySpace(obj.value)) {
            obj.validation.isValid = false
            return obj
        }

        switch (obj.validation.regex.constructor) {
            case String:
                if (!isNullOrEmptySpace(obj.type) &&
                    obj.type.toUpperCase() === "SELECT" &&
                    obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = (!isNullOrEmptySpace(obj.value) && parseInt(obj.value) > 0)
                else if (!isNullOrEmptySpace(obj.type) &&
                    typesDecInt.includes(obj.type.toUpperCase()) &&
                    obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = (!isNullOrEmptySpace(obj.value) && parseInt(obj.value) > 0)
                else if (obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = !isNullOrEmptySpace(obj.value)

                break;
            case RegExp:
                obj.validation.isValid = isNullOrEmptySpace(obj.value) ? false : !isNullOrEmptySpace(obj.validation.regex.exec(obj.value))
                break;
            default:
                obj.validation.isValid = true
        }
        return obj
    }

    validationForm = () => {
        const { alert } = this.state
        const newProgram = this.state.form;
        var result = true;
        var elements = Object.keys(this.state.form)
        elements.forEach(name => {
            newProgram[name] = this.validation(newProgram[name])
            if (!newProgram[name].validation.isValid)
                result = false
        })

        if (this.state.form.password.value !== this.state.form.passwordReplay.value) {
            alert.message = "As senhas são diferentes"
            result = false
        }

        this.setState({ form: newProgram, alert })
        return result;
    }

    onChange = (ev) => {
        const newProgram = this.state.form;
        const { name, value } = ev.currentTarget;

        if (!isNullOrEmptySpace(newProgram[name].type) && newProgram[name].type.toUpperCase() === "select") {
            try {
                newProgram[name].value = parseInt(value);
            }
            catch {
                newProgram[name].value = value;
            }
        }
        else
            newProgram[name].value = value;

        newProgram[name] = this.validation(newProgram[name])

        this.setState({ form: newProgram })
    }

    componentDidMount() {

        if (!isNullOrEmptySpace(this.props.params.key)) {
            this.validationKey(this.props.params.key)
        } else {
            let { alert } = this.state
            alert.color = "danger"
            alert.message = "Sem acesso"
            this.setState({ alert, isInvalid: true })
        }

    }

    validationKey = (key) => {
        const _this = this
        var obj = {
            DataSourceMethod: 'GET',
            DataSourceUrl: getUrlBackEnd() + 'forgot/validation/' + key,
            DataSourceHeader: {
                'content-type': 'application/json',
            }
        }

        ExecuteDataSource(obj).then(function (response) {
            _this.setState({ user: response, isInvalid: false })

        }).catch(function (error) {
            let { alert } = _this.state
            console.error(error)
            alert.color = "danger"
            alert.message = "Sem acesso"
            _this.setState({ alert, isInvalid: true })
        });
    }

    onClickSave = (e) => {
        const { form } = this.state
        const { alert } = this.state
        let _this = this

        alert.color = "danger"
        alert.message = null
        this.setState({ alert })

        if (!this.validationForm()) {
            return
        }
        var objElement = {
            email: this.state.user.email,
            password:form.password.value
        }

        var obj = {
            DataSourceMethod: 'POST',
            DataSourceUrl: getUrlBackEnd() + 'forgot/changepass/'+this.props.params.key,
            DataSourceData: objElement,
            DataSourceHeader: {
                'content-type': 'application/json',
            }
        }

        ExecuteDataSource(obj).then(function (response) {
            let { alert } = _this.state
            alert.color = "success"
            alert.message = "Senha alterada com sucesso"
            _this.setState({ alert})

        }).catch(function (error) {
            let { alert } = _this.state
            console.error(error)
            alert.color = "danger"
            alert.message = "Erro ao realizar alteração, por favor tente mais tarde"
            _this.setState({ alert})
        });



    }

    render() {
        const Alerts = () => {
            if (!isNullOrEmptySpace(this.state.alert.message))
                return (
                    <UncontrolledAlert color={this.state.alert.color}>
                        {this.state.alert.message}
                    </UncontrolledAlert>
                )
        }
        const Forms = () => {
            if (this.state.isInvalid && this.state.alert.message == null)
                return (<div className="d-flex justify-content-center align-items-center">
                    <img
                        alt="logo"
                        src={process.env.PUBLIC_URL + "/image/progress.gif"}
                        width="20%"
                    />
                </div>)
            else if (this.state.isInvalid && !isNullOrEmptySpace(this.state.alert.message)) {
                return (<></>)
            }
            else
                return (
                    <Form className="forms-sample" >
                        <FormGroup>
                            <Label for="password">
                                {this.state.form.password.label}
                            </Label >
                            <Input invalid={!this.state.form.password.validation.isValid ? true : undefined}
                                onChange={(e) => this.onChange(e)}
                                name="password"
                                type="password"
                                value={this.state.form.password.value}
                                validation={this.state.form.password.validation.regex} />
                            <FormFeedback invalid={!this.state.form.password.validation.isValid ? true : undefined}>
                                {this.state.form.password.validation.error}
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="passwordReplay">
                                {this.state.form.passwordReplay.label}
                            </Label >
                            <Input invalid={!this.state.form.passwordReplay.validation.isValid ? true : undefined}
                                onChange={(e) => this.onChange(e)}
                                name="passwordReplay"
                                type="password"
                                value={this.state.form.passwordReplay.value}
                                validation={this.state.form.passwordReplay.validation.regex} />
                            <FormFeedback invalid={!this.state.form.passwordReplay.validation.isValid ? true : undefined}>
                                {this.state.form.passwordReplay.validation.error}
                            </FormFeedback>
                        </FormGroup>
                        <Row>
                            <FormGroup>
                                <Button color="deore" className="btn-primary" onClick={() => this.onClickSave()}>Proximo</Button>
                            </FormGroup>
                        </Row>
                    </Form>
                )
        }
        return (
            <div className="container-scroller">
                <div className="navbar default-layout col-lg-12 col-12 p-0  fixed-top d-flex align-items-top flex-row">
                    <div className="navbar-menu-wrapper d-flex justify-content-center">
                        <div className="navbar-nav">
                            <h1>Astro Connect </h1>
                        </div>
                    </div>
                </div>
                <Container className="page-body-wrapper" fluid >
                    <Col className="align-items-center">
                        <br />
                        <Row className="row w-100 mx-0">
                            <Col className="col-lg-4 mx-auto">
                                <Card>
                                    <CardBody>
                                        <h2>Refinição de Senha</h2>
                                        <br />
                                        {Alerts()}
                                        {Forms()}
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                    </Col>
                </Container>
            </div>
        )
    }
}

export default withParams(Forgot);