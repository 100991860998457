import React from "react";
import DataTable from "react-data-table-component";
import { Button, FormGroup, Row } from "reactstrap";

class ContactList extends React.Component {

    customStyles = () => {
        return {
            headRow: {
                style: {
                    border: 'none',

                },
            },
            headCells: {
                style: {
                    color: '#202124',
                    fontSize: '14px',

                },
            },
            rows: {
                highlightOnHoverStyle: {
                    backgroundColor: 'rgb(230, 244, 244)',
                    borderBottomColor: '#FFFFFF',
                    borderRadius: '25px',
                    outline: '1px solid #FFFFFF',

                },
            },
            pagination: {
                style: {
                    border: 'none',

                },
            }

        }
    }

    render() {
        const columns = [
            {
                name: 'Nome',
                selector: row => row.name,
                sortable: true,
                style: {
                    color: 'rgba(0,0,0,.54)',
                },
            },
            {
                name: 'Telefone',
                selector: row => "+"+row.ddi +" "+ row.phone,
                sortable: true,
                style: {
                    color: 'rgba(0,0,0,.54)',
                },
            },
            {
                name: 'Empresa',
                selector: row => row.company,
                sortable: true,
                style: {
                    color: 'rgba(0,0,0,.54)',
                },
            },
            {
                name: 'E-mail',
                selector: row => row.email,
                sortable: true,
                style: {
                    color: 'rgba(0,0,0,.54)',
                },
            },
            {
                name: 'Categoria',
                selector: row => row.category,
                sortable: true,
                style: {
                    color: 'rgba(0,0,0,.54)',
                },
            },
           
        ];
        return (
            <>
                <Row>
                    <DataTable
                        columns={columns}
                        data={this.props.data}
                        customStyles={this.customStyles}
                    />
                </Row>
                <Row>
                    <FormGroup>
                        <Button className="me-2" onClick={() => window.location.href="contacts"}>Cancelar</Button>
                        <Button color="deore" className="btn-primary">Salvar</Button>

                    </FormGroup>
                </Row>
            </>
        )
    }
}
export default ContactList;