import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import RouterMaster from './RouterMaster';
import { BrowserRouter  } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('astrowhatsapp'));
root.render(
    <BrowserRouter>
      <RouterMaster />
    </BrowserRouter>
);
