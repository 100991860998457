
import React, { useState } from "react"
import { MdMenu } from "react-icons/md"
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, InputGroupText } from "reactstrap"
import { logoff } from "../data/Auth"
import { getUser } from "../data/Auth"
import { GiExitDoor } from "react-icons/gi";

const TopBar = (props) => {
    const [toggle, setToggle] = useState(false);

    const exitLog = () => {
        logoff()
    }
    const greetingMessage = () => {
        let h = new Date().getHours();
        switch (true) {
            case h <= 5: return 'Boa madrugada';
            case h < 12: return 'Bom dia';
            case h < 18: return 'Boa tarde';
            default: return 'Boa noite';
        }
    }

    return (
        <div className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
            <div className="navbar-menu-wrapper d-flex align-items-top">
                <div className="navbar-nav">
                    <h1>AstroWhatsApp</h1>
                </div>
                <div className="navbar-nav ms-auto">
                    <h3>{greetingMessage()}, <span>{getUser().name}</span></h3>
                    &emsp;&emsp;
                    <InputGroupText className="click" >
                        <GiExitDoor size={40} onClick={() => exitLog()} />
                    </InputGroupText>
                </div>
            </div>
        </div>
    )
}

export default TopBar

