
import React from "react"
import TopBar from "../header/SystemTopBar"
import { getUser, isLogint } from "../data/Auth"
import { Container, InputGroup, InputGroupText, Row } from "reactstrap";
import { Outlet } from "react-router-dom"
import { setUserInternal } from "../pages/user/ComponentsUser";
import { BsTelephoneInbound } from "react-icons/bs";
import { AiOutlineMessage, AiOutlineUser } from "react-icons/ai";
import { TypeUser } from "../pages/user/ComponentsUser";

class System extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openMenu: false
        }
        if (!isLogint()) {
            window.location.href = "/login"
        }
    }

    doSomethingBeforeUnload = () => {
        console.log("Saindo")
    }

    setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            return this.doSomethingBeforeUnload();
        });
    };

    componentDidMount() {
        this.setupBeforeUnloadListener();
        if (!isLogint()) {
            window.location.href = "/login"
        }
        else {
            setUserInternal()
        }
    }


    onClick() {
        this.setState(prevState => ({
            openMenu: !prevState.openMenu
        }))
    }

    render() {
        const client = () => {
            if (getUser().type === TypeUser.MASTER)
                return (
                    <InputGroupText className="click" >
                        <AiOutlineUser size={50} onClick={() => window.location.href = "/client"} />
                    </InputGroupText>
                )
        }

        const contact = () => {
            if (getUser().type === TypeUser.ADMIN)
                return (
                    <InputGroupText className="click" >
                        <BsTelephoneInbound size={50} onClick={() => window.location.href = "/contacts"} />
                    </InputGroupText>
                )
        }

        const batch = () => {
            if (getUser().type === TypeUser.ADMIN)
                return (
                    <InputGroupText className="click" >
                        <AiOutlineMessage size={50} onClick={() => window.location.href = "/batch"} />
                    </InputGroupText>
                )
        }

        return (
            <div className="container-scroller">
                <TopBar />
                <Container className="page-body-wrapper" fluid >
                    <div className="main-panel">
                        <Row className="tool_bar">
                            <InputGroup>
                                {client()}
                                {contact()}
                                {batch()}
                            </InputGroup>
                        </Row>
                        <div className="content-wrapper">
                            <Outlet />
                        </div>
                    </div>
                </Container>

            </div>

        )
    }

}

export default System;