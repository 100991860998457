import React from "react"
import { Col, Form, FormGroup, Row, UncontrolledAlert, Label, Input, FormFeedback, Button } from "reactstrap";
import { isNullOrEmptySpace } from "../../framework/AstroNetUtility";
import { getUrlBackEnd, getUser } from "../../data/Auth";
import { ExecuteDataSource } from "../../framework/AstroNetREST";


class StatusTalkHandle extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            form: {
                value: {
                    label: "Valor",
                    validation: {
                        error: "Valor Obrigatório",
                        regex: "Required",
                        isValid: true
                    }
                },
                text: {
                    label: "Texto",
                    validation: {
                        error: "Texto Obrigatório",
                        regex: "Required",
                        isValid: true
                    }
                },
                isActive: {
                    label: "Ativo",
                    validation: {
                        error: "Status Obrigatório",
                        regex: "Required",
                        isValid: true
                    }
                },
            },
            alert: {
                color: "danger",
                message: null
            }
        }
    }

    componentWillMount() {
        const { form } = this.state
        form.value.value =  this.props.form.value;
        form.text.value =  this.props.form.text;
        form.isActive.value =  this.props.form.isActive;

        this.setState({form})
    }

    onChange = (ev) => {
        const newProgram = this.state.form;
        const { name, value } = ev.currentTarget;

        if (!isNullOrEmptySpace(newProgram[name].type) && newProgram[name].type.toUpperCase() == "SELECT") {
            try {
                newProgram[name].value = parseInt(value);
            }
            catch {
                newProgram[name].value = value;
            }
        }
        else
            newProgram[name].value = value;

        newProgram[name] = this.validation(newProgram[name])
        this.setState({ form: newProgram })
    }

    validation = (obj) => {
        let typesDecInt = ["INT", "DECIMAL"]
        if (isNullOrEmptySpace(obj.validation) || isNullOrEmptySpace(obj.validation.regex)) {
            obj.validation.isValid = true
            return obj
        }

        if (!isNullOrEmptySpace(obj.type) && typesDecInt.includes(obj.type.toUpperCase()) && isNullOrEmptySpace(obj.value)) {
            obj.validation.isValid = false
            return obj
        }

        switch (obj.validation.regex.constructor) {
            case String:
                if (!isNullOrEmptySpace(obj.type) &&
                    obj.type.toUpperCase() == "SELECT" &&
                    obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = (!isNullOrEmptySpace(obj.value) && parseInt(obj.value) > 0)
                else if (!isNullOrEmptySpace(obj.type) &&
                    typesDecInt.includes(obj.type.toUpperCase()) &&
                    obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = (!isNullOrEmptySpace(obj.value) && parseInt(obj.value) > 0)
                else if (obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = !isNullOrEmptySpace(obj.value)

                break;
            case RegExp:
                obj.validation.isValid = !isNullOrEmptySpace(obj.validation.regex.exec(obj.value))
                break;
            default:
                obj.validation.isValid = true
        }
        return obj
    }

    validationForm = () => {
        const newProgram = this.state.form;
        var result = true;
        var elements = Object.keys(this.state.form)
        elements.forEach(name => {
            newProgram[name] = this.validation(newProgram[name])
            if (!newProgram[name].validation.isValid)
                result = false
        })
        this.setState({ form: newProgram })

        return result;
    }

    cancel = (event) => {
        const { value } = this.props;
        value(
            event
        )
    }

    save = (event) => {
        let _this = this
        if (!this.validationForm()) {
            return
        }
        const { form } = this.state

        var elements = Object.keys(form)
        var objElement = {};
        elements.map(x => {
            if (!isNullOrEmptySpace(form[x].type) && form[x].type.toLocaleLowerCase() == "decimal" && form[x].value.constructor == String && !isNullOrEmptySpace(form[x].value))
                form[x].value = form[x].value.replace(".", "").replace(",", ".")

            if (x.toLocaleLowerCase() !== "validation")
                objElement[x] = form[x].value

        })

        var obj = {
            DataSourceMethod: 'POST',
            DataSourceUrl: getUrlBackEnd() + 'whatsapp/status/save/' + this.props.tokenWhatsApp,
            DataSourceData: objElement,
            DataSourceHeader: {
                'content-type': 'application/json',
                'Authorization': getUser().token
            }
        }

        ExecuteDataSource(obj).then(function (response) {
            const { value } = _this.props;
            value(
                event
            )

        }).catch(function (error) {
            let { alert } = _this.state
            console.error(error)
            alert.message = error.response.data.Message
            alert.color = "danger"
            _this.setState({ alert })
        });
    }

    render = () => {
        const Alerts = () => {
            if (!isNullOrEmptySpace(this.state.alert.message))
                return (
                    <UncontrolledAlert color={this.state.alert.color}>
                        {this.state.alert.message}
                    </UncontrolledAlert>
                )
        }
        return (<>
            {Alerts()}
            <Form className="forms-sample">
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="value">
                                {this.state.form.value.label}
                            </Label >
                            <Input invalid={!this.state.form.value.validation.isValid ? true : undefined}
                                onChange={(e) => this.onChange(e)}
                                name="value"
                                value={this.state.form.value.value}
                                validation={this.state.form.value.validation.regex}
                                type="number"
                            />
                            <FormFeedback invalid={!this.state.form.value.validation.isValid ? true : undefined}>
                                {this.state.form.value.validation.error}
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="text">
                                {this.state.form.text.label}
                            </Label >
                            <Input invalid={!this.state.form.text.validation.isValid ? true : undefined}
                                onChange={(e) => this.onChange(e)}
                                name="text"
                                value={this.state.form.text.value}
                                validation={this.state.form.text.validation.regex}
                            />
                            <FormFeedback invalid={!this.state.form.text.validation.isValid ? true : undefined}>
                                {this.state.form.text.validation.error}
                            </FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="isActive">
                                {this.state.form.isActive.label}
                            </Label >
                            <Input invalid={!this.state.form.isActive.validation.isValid ? true : undefined}
                                onChange={(e) => this.onChange(e)}
                                name="isActive"
                                value={this.state.form.isActive.value}
                                validation={this.state.form.isActive.validation.regex}
                            />
                            <FormFeedback invalid={!this.state.form.isActive.validation.isValid ? true : undefined}>
                                {this.state.form.isActive.validation.error}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <FormGroup>
                        <Button name="cancel" className="me-2" onClick={(e) => this.cancel(e)}>Cancelar</Button>
                        <Button name="save" color="deore" className="btn-primary" onClick={(e) => this.save(e)}>Salvar</Button>

                    </FormGroup>
                </Row>
            </Form>
        </>)

    }
}

export default StatusTalkHandle;