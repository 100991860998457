
import React from "react"
import LoginPage from "../pages/login/Login"

class Login extends React.Component {
    render() {
        return (
            <LoginPage />
        )
    }

}

export default Login;