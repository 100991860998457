import { getUrlBackEnd, getUser } from "../../data/Auth";
import { ExecuteDataSource } from "../../framework/AstroNetREST";
import { isNullOrEmptySpace } from "../../framework/AstroNetUtility";

export const TypeUser = {
    MASTER: 1,
    ADMIN: 2,
    USER: 3,
}


export function getTypeUser(type) {

    switch (type) {
        case 1:
            return "Astro"
            break;
        case 2:
            return "Administrador"
            break;
        case 3:
            return "Usuário"
            break;
    }

}

export async function setUserInternal() {
    let as = window.sessionStorage.getItem("astrowsurs")
    if (!isNullOrEmptySpace(as))
        return
   
    var obj = {
        DataSourceMethod: 'GET',
        DataSourceUrl: getUrlBackEnd() + 'user/get',
        DataSourceHeader: {
            'content-type': 'application/json',
            'Authorization': getUser().token
        }
    }

    ExecuteDataSource(obj).then(function (response) {

        let list = response.map(x => {
            return (
                {
                    name: x.name,
                    id: x.token
                }
            )
        })
        window.sessionStorage.setItem("astrowsurs", JSON.stringify(list))

    }).catch(function (error) {
        console.error(error)
    });
}

export function getUserInternal(objT) {
    if (isNullOrEmptySpace(objT))
        return "Integração"

    let as = window.sessionStorage.getItem("astrowsurs")
    if (isNullOrEmptySpace(as))
        return "Integração"
    let jas = JSON.parse(as)
    let fil = jas.filter(x => x.id == objT)

    if (fil.length > 0)
        return fil[0].name
    else
        return "Integração"

}

