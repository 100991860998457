import React from "react"
import {
    Card, CardBody,
    UncontrolledAlert, FormGroup, Input, Button, Row, Col, Label, UncontrolledTooltip
} from "reactstrap"
import DataTable from "react-data-table-component"
import { isNullOrEmptySpace, paginationComponentOptions } from "../../framework/AstroNetUtility"
import ClientHandle from "./ClientPhoneHandle"
import { ExecuteDataSource, ExecuteDataSourceSync } from "../../framework/AstroNetREST";
import { getUrlBackEnd, getUser } from "../../data/Auth"
import { MdOutlineAssignme } from "react-icons/md"
import ClientPhoneHandle from "./ClientPhoneHandle"

class ClientPhoneHome extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            alert: {
                color: "danger",
                message: null
            }
        }
    }

    onChange = (ev) => {
        const newProgram = this.state;
        const { name, value } = ev.currentTarget;
        newProgram[name] = value;
        this.filter(value)
        this.setState({ newProgram })
    }

    filter(value) {
        const newProgram = this.state;

        if (isNullOrEmptySpace(value))
            newProgram.listNew = this.state.list

        if (!isNullOrEmptySpace(this.state.list) && !isNullOrEmptySpace(value)) {
            newProgram.listNew = this.state.list.filter(x => x.name.toUpperCase().includes(value.toUpperCase()) ||
                x.alias.toUpperCase().includes(value.toUpperCase()) ||
                x.keyClient.toUpperCase().includes(value.toUpperCase()))
        }
        this.setState({ newProgram })
    }

    customStyles = () => {
        return {
            headRow: {
                style: {
                    border: 'none',

                },
            },
            headCells: {
                style: {
                    color: '#202124',
                    fontSize: '14px',

                },
            },
            rows: {
                highlightOnHoverStyle: {
                    backgroundColor: 'rgb(230, 244, 244)',
                    borderBottomColor: '#FFFFFF',
                    borderRadius: '25px',
                    outline: '1px solid #FFFFFF',

                },
            },
            pagination: {
                style: {
                    border: 'none',

                },
            }

        }
    }

    onClickRow = (row) => {
        this.setState({
            selected: row
        })
    }

    handle = () => {
        this.recharge();
        this.setState({
            selected: null
        })
    }

    new = () => {
        let obj = {};
        obj.clientId = this.props.value.idClient
        this.setState({
            selected: obj
        })
    }

    componentWillMount() {
        this.recharge();
    }

    recharge() {
        if (isNullOrEmptySpace(this.props.value.idClient))
            return
        let _this = this;

        var obj = {
            DataSourceMethod: 'GET',
            DataSourceUrl: getUrlBackEnd() + 'whatsapp/get/list/' + this.props.value.idClient,
            DataSourceHeader: {
                'content-type': 'application/json',
                'Authorization': getUser().token
            }
        }

        ExecuteDataSource(obj).then(function (response) {
            _this.setState({ list: response, listNew: response })
            if (!isNullOrEmptySpace(_this.state.filter))
                _this.filter(_this.state.filter)

        }).catch(function (error) {
            let { alert } = _this.state
            console.error(error)
            alert.message = error.response.data.Message
            alert.color = "danger"
            _this.setState({ alert })
        });
    }

    onClickInstalation = (row) => {
        this.setState({
            install: row
        })
    }

    render = () => {

        const columns = [
            {
                name: 'Nome',
                selector: row => row.name,
                sortable: true,
                style: {
                    color: 'rgba(0,0,0,.54)',
                },
            },
            {
                name: 'Telefone',
                selector: row => row.phone,
                sortable: true,
                style: {
                    color: 'rgba(0,0,0,.54)',
                },
            },
            {
                name: 'Ativo',
                sortable: true,
                style: {
                    color: 'rgba(0,0,0,.54)',
                },
                cell: (row) =>
                    <>
                        <span>{row.status ? "Sim" : "Não"}</span>
                    </>
            },
        ];

        const Alerts = () => {
            if (!isNullOrEmptySpace(this.state.alert.message))
                return (
                    <UncontrolledAlert color={this.state.alert.color}>
                        {this.state.alert.message}
                    </UncontrolledAlert>
                )
        }


        if (!isNullOrEmptySpace(this.state.selected))
            return (<ClientPhoneHandle Handle client={this.state.selected} value={() => this.handle()} />)
        else
            return (
                <DataTable
                    columns={columns}
                    data={this.state.listNew}
                    customStyles={this.customStyles}
                    noDataComponent="Não encontrado"
                    highlightOnHover
                    pointerOnHover
                    onRowClicked={(row) => this.onClickRow(row)}
                    actions={<Button color="deore" className="btn-primary" onClick={() => this.new()}>Novo</Button>}
                />
            )
    }
}

export default ClientPhoneHome;