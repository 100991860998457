import React from "react"
import {
    Card, CardBody,
    Form, FormGroup, InputGroup,
    Label, Input, FormFeedback, UncontrolledAlert,
    Button, Col, Row,
} from "reactstrap"

import { convertToBase64Encode, isNullOrEmptySpace } from "../../framework/AstroNetUtility"
import PropTypes from 'prop-types';
import { ExecuteDataSourceSync, ExecuteDataSource } from "../../framework/AstroNetREST";
import { getUrlBackEnd, getUser } from "../../data/Auth"
import InputMask from "react-input-mask"
import { TypeUser } from "../user/ComponentsUser";
import ClientPhoneHome from "./ClientPhoneHome";

class ClientHandle extends React.Component {

    static propTypes = {
        value: PropTypes.func,
    }

    constructor(props) {
        super(props)
        this.state = {
            form: {
                id: {
                    label: "Id",
                    validation: {
                        isValid: true
                    }
                },
                name: {
                    label: "Nome",
                    validation: {
                        error: "Nome Invalido",
                        regex: "Required",
                        isValid: true
                    }
                },
                email: {
                    label: "E-mail",
                    validation: {
                        error: "E-mail Invalido",
                        regex: new RegExp("^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?"),
                        isValid: true
                    }
                },
                owner: {
                    label: "Dono",
                    validation: {
                        error: "Dono Invalido",
                        regex: "Required",
                        isValid: true
                    }
                },
                ceo: {
                    label: "CEO",
                    validation: {
                        error: "CEO Invalido",
                        regex: "Required",
                        isValid: true
                    }
                },
                cto: {
                    label: "CTO",
                    validation: {
                        error: "CTO Invalido",
                        regex: "Required",
                        isValid: true
                    }
                },
                contact: {
                    label: "Contato",
                    validation: {
                        error: "Nome Invalido",
                        regex: "Required",
                        isValid: true
                    }
                },
                status: {
                    label: "Status",
                    type: "select",
                    validation: {
                        isValid: true
                    }
                },
                packageLimiteDisk: {
                    label: "Limite de Usado de Disco(MB)",
                    validation: {
                        isValid: true
                    }
                },
            },
            alert: {
                color: "danger",
                message: null
            }
        }
    }

    componentWillMount() {
        if (getUser().type !== TypeUser.MASTER)
            window.location.href = '/nopermission'

        const { client } = this.props
        const { form } = this.state

        var elements = Object.keys(form)
        elements.map(x => {
            if (x === "status")
                form[x].value = client[x] ? 1 : 0
            else
                form[x].value = client[x]
        })

        console.log(form)

        this.setState({ form })
    }

    onChange = (ev) => {
        const newProgram = this.state.form;
        const { name, value } = ev.currentTarget;

        if (!isNullOrEmptySpace(newProgram[name].type) && newProgram[name].type.toUpperCase() == "SELECT") {
            try {
                newProgram[name].value = parseInt(value);
            }
            catch {
                newProgram[name].value = value;
            }
        }
        else
            newProgram[name].value = value;

        newProgram[name] = this.validation(newProgram[name])
        this.setState({ form: newProgram })
    }

    validation = (obj) => {
        let typesDecInt = ["INT", "DECIMAL"]
        if (isNullOrEmptySpace(obj.validation) || isNullOrEmptySpace(obj.validation.regex)) {
            obj.validation.isValid = true
            return obj
        }

        if (!isNullOrEmptySpace(obj.type) && typesDecInt.includes(obj.type.toUpperCase()) && isNullOrEmptySpace(obj.value)) {
            obj.validation.isValid = false
            return obj
        }

        switch (obj.validation.regex.constructor) {
            case String:
                if (!isNullOrEmptySpace(obj.type) &&
                    obj.type.toUpperCase() == "SELECT" &&
                    obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = (!isNullOrEmptySpace(obj.value) && parseInt(obj.value) > 0)
                else if (!isNullOrEmptySpace(obj.type) &&
                    typesDecInt.includes(obj.type.toUpperCase()) &&
                    obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = (!isNullOrEmptySpace(obj.value) && parseInt(obj.value) > 0)
                else if (obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = !isNullOrEmptySpace(obj.value)

                break;
            case RegExp:
                obj.validation.isValid = !isNullOrEmptySpace(obj.validation.regex.exec(obj.value))
                break;
            default:
                obj.validation.isValid = true
        }
        return obj
    }

    validationForm = () => {
        const newProgram = this.state.form;
        var result = true;
        var elements = Object.keys(this.state.form)
        elements.forEach(name => {
            newProgram[name] = this.validation(newProgram[name])
            if (!newProgram[name].validation.isValid)
                result = false
        })
        this.setState({ form: newProgram })

        return result;
    }

    cancel = (event) => {
        const { value } = this.props;
        value(
            event
        )
    }

    save = (form) => {
        let _this = this
        if (!this.validationForm()) {
            return
        }
        form = this.state.form

        var elements = Object.keys(form)
        var objElement = {};
        elements.map(x => {
            if (!isNullOrEmptySpace(form[x].type) && form[x].type.toLocaleLowerCase() == "decimal" && form[x].value.constructor == String && !isNullOrEmptySpace(form[x].value))
                form[x].value = form[x].value.replace(".", "").replace(",", ".")

            if (x.toLocaleLowerCase() !== "validation")
                objElement[x] = form[x].value

        })
        if (!isNullOrEmptySpace(objElement.externalJsonDefaultContact))
            objElement.externalJsonDefaultContact = JSON.parse(objElement.externalJsonDefaultContact)
        else
            objElement.externalJsonDefaultContact = null

        var obj = {
            DataSourceMethod: 'POST',
            DataSourceUrl: getUrlBackEnd() + 'client/save',
            DataSourceData: objElement,
            DataSourceHeader: {
                'content-type': 'application/json',
                'Authorization': getUser().token
            }
        }

        ExecuteDataSource(obj).then(function (response) {
            let { alert } = _this.state
            alert.color = "success"
            alert.message = "Salvo com Sucesso"
            _this.setState({ alert })

        }).catch(function (error) {
            let { alert } = _this.state
            console.error(error)
            alert.message = error.response.data.Message
            alert.color = "danger"
            _this.setState({ alert })
        });
    }

    getHash(alias) {
        var obj = {
            DataSourceMethod: "GET",
            DataSourceUrl: getUrlBackEnd() + 'client/hash/' + alias,
            DataSourceHeader: {
                "Content-Type": "application/json",
                "accept": "text/plain"
            },
        }
        var resp = ExecuteDataSourceSync(obj)
        return resp
    }

    getKey(alias) {
        var obj = {
            DataSourceMethod: "GET",
            DataSourceUrl: getUrlBackEnd() + 'client/key/' + alias,
            DataSourceHeader: {
                "Content-Type": "application/json",
                "accept": "text/plain"
            },
        }
        var resp = ExecuteDataSourceSync(obj)
        return resp
    }

    onClickHash() {
        let { form } = this.state
        if (isNullOrEmptySpace(this.state.form.alias.value)) {
            form.keySaltHash.validation.error = "Abreviatura Invalido"
            form.keySaltHash.validation.isValid = false;
            this.setState({ form })
            return
        }

        var resp = this.getHash(this.state.form.alias.value)

        if (resp.StatusCode === 599) {
            form.keySaltHash.value = "";
            form.keySaltHash.validation.error = resp.Message;
            form.keySaltHash.validation.isValid = false;
        }
        else {

            form.keySaltHash.value = resp;
            form.keySaltHash.validation.isValid = true;
        }

        this.setState({ form })

    }

    onClickKey() {
        let { form } = this.state
        if (isNullOrEmptySpace(this.state.form.alias.value)) {
            form.keyClient.validation.error = "Abreviatura Invalido"
            form.keyClient.validation.isValid = false;
            this.setState({ form })
            return
        }
        var resp = this.getKey(this.state.form.alias.value)

        if (resp.StatusCode === 599) {
            form.keyClient.value = "";
            form.keyClient.validation.error = resp.Message
            form.keyClient.validation.isValid = false;
        }
        else {
            form.keyClient.value = resp;
            form.keyClient.validation.isValid = true;
        }

        this.setState({ form })
    }

    render() {
        this.state.form.status.value = isNullOrEmptySpace(this.state.form.status.value) ? 1 : this.state.form.status.value
        const Alerts = () => {
            if (!isNullOrEmptySpace(this.state.alert.message))
                return (
                    <UncontrolledAlert color={this.state.alert.color}>
                        {this.state.alert.message}
                    </UncontrolledAlert>
                )
        }

        return (
            <>
                <Card>
                    <CardBody>
                        <h4 className="card-title">Cadastro Cliente</h4>
                        <br />
                        {Alerts()}
                        <Form className="forms-sample">
                            <Row>
                                <Col>

                                    <FormGroup>
                                        <Label for="name">
                                            {this.state.form.name.label}
                                        </Label >
                                        <Input invalid={!this.state.form.name.validation.isValid ? true : undefined}
                                            onChange={(e) => this.onChange(e)}
                                            name="name"
                                            value={this.state.form.name.value}
                                            validation={this.state.form.name.validation.regex}
                                        />
                                        <FormFeedback invalid={!this.state.form.name.validation.isValid ? true : undefined}>
                                            {this.state.form.name.validation.error}
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="email">
                                            {this.state.form.email.label}
                                        </Label >
                                        <Input invalid={!this.state.form.email.validation.isValid ? true : undefined}
                                            onChange={(e) => this.onChange(e)}
                                            name="email"
                                            value={this.state.form.email.value}
                                            validation={this.state.form.email.validation.regex} />
                                        <FormFeedback invalid={!this.state.form.email.validation.isValid ? true : undefined}>
                                            {this.state.form.email.validation.error}
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="status">
                                            {this.state.form.status.label}
                                        </Label >
                                        <Input invalid={!this.state.form.status.validation.isValid ? true : undefined}
                                            onChange={(e) => this.onChange(e)}
                                            name="status"
                                            value={this.state.form.status.value}
                                            validation={this.state.form.status.validation.regex}
                                            type="select">
                                            <option key={1} value={1}>
                                                Ativo
                                            </option>
                                            <option key={0} value={0}>
                                                Inativo
                                            </option>
                                        </Input>
                                        <FormFeedback invalid={!this.state.form.status.validation.isValid ? true : undefined}>
                                            {this.state.form.status.validation.error}
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="packageLimiteDisk">
                                            {this.state.form.packageLimiteDisk.label}
                                        </Label >
                                        <Input invalid={!this.state.form.packageLimiteDisk.validation.isValid ? true : undefined}
                                            onChange={(e) => this.onChange(e)}
                                            name="packageLimiteDisk"
                                            value={this.state.form.packageLimiteDisk.value}
                                            type="number"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="contact">
                                            {this.state.form.contact.label}
                                        </Label >
                                        <Input invalid={!this.state.form.contact.validation.isValid ? true : undefined}
                                            onChange={(e) => this.onChange(e)}
                                            name="contact"
                                            value={this.state.form.contact.value}
                                            validation={this.state.form.contact.validation.regex} />
                                        <FormFeedback invalid={!this.state.form.contact.validation.isValid ? true : undefined}>
                                            {this.state.form.contact.validation.error}
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="owner">
                                            {this.state.form.owner.label}
                                        </Label >
                                        <Input invalid={!this.state.form.owner.validation.isValid ? true : undefined}
                                            onChange={(e) => this.onChange(e)}
                                            name="owner"
                                            value={this.state.form.owner.value}
                                            validation={this.state.form.owner.validation.regex} />
                                        <FormFeedback invalid={!this.state.form.owner.validation.isValid ? true : undefined}>
                                            {this.state.form.owner.validation.error}
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="ceo">
                                            {this.state.form.ceo.label}
                                        </Label >
                                        <Input invalid={!this.state.form.ceo.validation.isValid ? true : undefined}
                                            onChange={(e) => this.onChange(e)}
                                            name="ceo"
                                            value={this.state.form.ceo.value}
                                            validation={this.state.form.ceo.validation.regex} />
                                        <FormFeedback invalid={!this.state.form.ceo.validation.isValid ? true : undefined}>
                                            {this.state.form.ceo.validation.error}
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="cto">
                                            {this.state.form.cto.label}
                                        </Label >
                                        <Input invalid={!this.state.form.cto.validation.isValid ? true : undefined}
                                            onChange={(e) => this.onChange(e)}
                                            name="cto"
                                            value={this.state.form.cto.value}
                                            validation={this.state.form.cto.validation.regex} />
                                        <FormFeedback invalid={!this.state.form.cto.validation.isValid ? true : undefined}>
                                            {this.state.form.cto.validation.error}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <FormGroup>
                                    <Button className="me-2" onClick={() => this.cancel()}>Cancelar</Button>
                                    <Button color="deore" className="btn-primary" onClick={() => this.save()}>Salvar</Button>

                                </FormGroup>
                            </Row>
                        </Form>
                    </CardBody>
                </Card >
                <br />
                <Card>
                    <CardBody>
                        <ClientPhoneHome
                            value={{ idClient: this.props.client.id }} />
                    </CardBody>
                </Card>
            </>
        )
    }
}

ClientHandle.defaultProps = {
    props: {},
    value: () => null,
};

ClientHandle.propTypes = {
    props: PropTypes.object,
    value: PropTypes.func,
};

export default ClientHandle;