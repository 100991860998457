import { convertToBase64Encode, isNullOrEmptySpace } from "../framework/AstroNetUtility"

var key = convertToBase64Encode(window.location.hostname)
export function logoff() {
    window.localStorage.removeItem(key + "AstroWhatsApp.UrlBackEnd")
    window.localStorage.removeItem(key + "AstroWhatsApp.User")
    window.location.href = "/login"
}

export function isLogint() {
    try {
        var astroWhatsAppUser = window.localStorage.getItem(key + "AstroWhatsApp.User")
        if (isNullOrEmptySpace(astroWhatsAppUser))
            return false;

        var userString = JSON.parse(window.localStorage.getItem(key + "AstroWhatsApp.User"))
        return !isNullOrEmptySpace(userString.token)
    }
    catch
    {
        return false;
    }
}

export function getUser() {
    try {
        var userString = window.localStorage.getItem(key + "AstroWhatsApp.User")
        if (isNullOrEmptySpace(userString))
            return;

        return JSON.parse(userString)
    }
    catch
    {
        return;
    }

}
export function setUser(user) {
    window.localStorage.setItem(key + "AstroWhatsApp.User", JSON.stringify(user))
}

export function exitUser() {
    window.localStorage.removeItem(key + "AstroWhatsApp.User")
}

export function getUrlFrontEnd() {
    return window.BASE_URL_API;
}

export function setUrlBackEnd(url){
    url = url + "/";
    window.localStorage.setItem(key + "AstroWhatsApp.UrlBackEnd", url)
}

export function getUrlBackEnd() {
    var urlString = window.localStorage.getItem(key + "AstroWhatsApp.UrlBackEnd")
    return urlString;
}