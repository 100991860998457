import moment from "moment";
import { UncontrolledAlert } from "reactstrap";

export function convertToBase64Encode(varString) {
	if (isNullOrEmptySpace(varString))
		return null

	var base64 = require('base-64');
	var utf8 = require('utf8');
	var bytes = utf8.encode(varString);
	var encoded = base64.encode(bytes);
	return encoded;
}
export function convertToBase64Decode(varString) {
	var base64 = require('base-64');
	var utf8 = require('utf8');
	var bytes = base64.decode(varString);
	var result = utf8.decode(bytes);
	return result;
}
export function stringToXMLDom(string) {
	var xmlDoc = null;
	if (window.DOMParser) {
		var parser = new DOMParser();
		xmlDoc = parser.parseFromString(string, "text/xml");
	}
	else // Internet Explorer
	{
		xmlDoc = new window.ActiveXObject("Microsoft.XMLDOM");
		xmlDoc.async = "false";
		xmlDoc.loadXML(string);
	}
	return xmlDoc;
}
export function replaceParameter(obj, value, type) {
	obj = JSON.stringify(obj);
	obj = obj.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
	type = type.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
	value = JSON.parse(JSON.stringify(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())

	var tags = obj.match(/\<\|(.+?)\|\>/g);
	if (isNullOrEmptySpace(tags))
		return JSON.parse(obj);

	tags.map(function (tag) {
		var tagclean = String(tag.match(/\w*\.\w*/gi))
		var tags = tagclean.split('.')
		if (tags.length > 1 && !isNullOrEmptySpace(tags[0]) && !isNullOrEmptySpace(tags[1])
			&& String(tags[0]).toLowerCase() === String(type).toLowerCase() && !isNullOrEmptySpace(value[tags[1].toLowerCase()])) {
			obj = obj.replace(tag, value[tags[1].toLowerCase()]);
		}
	})
	return JSON.parse(obj)
}

export function isNullOrEmptySpace(obj) {
	var result = true;
	if (obj !== undefined && obj !== null) {
		if (obj.constructor.name === "String" && obj.trim() !== "" && obj.trim().length > 0)
			return false
		if (obj.constructor.name === "Object" && obj !== {} && JSON.stringify(obj) !== "{}")
			return false
		if (obj.constructor.name === "Array" && obj.length > 0)
			return false
		if (obj.constructor.name === "Boolean")
			return false
		if (obj.constructor.name === "Number")
			return false
		if (obj.constructor.name === "Function")
			return false
		if (obj.constructor.name === "RegExp" && obj.source.length > 0)
			return false
		if (JSON.stringify(obj).trim() !== "" && JSON.stringify(obj) !== "{}" && JSON.stringify(obj).length > 2)
			return false
		if (JSON.stringify(obj).trim() !== "" && JSON.stringify(obj) !== "[]" && JSON.stringify(obj).length > 2)
			return false
	}
	return result;
}

export function refresh() {
	window.location.reload();
}
export function ExecuteSourceSync(obj, form, type) {
	var method = "get";
	var url = "";
	var headers = [];
	var send = [];
	var config = [];
	if (!isNullOrEmptySpace(form))
		obj = replaceParameter(obj, form, type)

	if (!isNullOrEmptySpace(obj.DataSourceMethod))
		method = obj.DataSourceMethod

	if (!isNullOrEmptySpace(obj.DataSourceUrl))
		url = obj.DataSourceUrl

	if (!isNullOrEmptySpace(obj.DataSourceBaseURL))
		config["baseURL"] = obj.DataSourceBaseURL

	if (!isNullOrEmptySpace(obj.DataSourceEncoding))
		config["responseEncoding"] = obj.DataSourceEncoding

	if (!isNullOrEmptySpace(obj.DataSourceContentType))
		config["responseEncoding"] = obj.DataSourceContentType

	if (!isNullOrEmptySpace(obj.DataSourceHeader))
		headers = obj.DataSourceHeader

	if (!isNullOrEmptySpace(obj.DataSourceData))
		send = obj.DataSourceData

	if (!isNullOrEmptySpace(obj.DataSourceTimeout))
		config["timeout"] = obj.DataSourceTimeout

	if (!isNullOrEmptySpace(obj.DataSourceAuth))
		config["auth"] = obj.DataSourceAuth

	if (!isNullOrEmptySpace(obj.DataSourceProxy))
		config["proxy"] = obj.DataSourceProxy

	let xhr = new XMLHttpRequest();
	xhr.open(method, url, false)
	xhr.setRequestHeader('Content-Type', 'application/json');
	xhr.send(send);

}

export function paginationComponentOptions() {
	return {
		rowsPerPageText: 'Filas por página',
		rangeSeparatorText: 'de',
		selectAllRowsItem: true,
		selectAllRowsItemText: 'Todos',
	};
}



export function convertJSONToArray(json) {
	if (isNullOrEmptySpace(json))
		return []
	var result = Object.keys(json).map(k => {
		var rVal = "";
		var rKey = k;

		if (json[k].constructor == Array) {
			rVal = "Lista com " + json[k].length + " itens"
		}
		else if (json[k].constructor == Object && Object.keys(json[k]).length > 0) {
			var listObjKey = Object.keys(json[k]);
			rKey = listObjKey[0];
			if (json[k][listObjKey[0]].constructor == Array) {
				rVal = "Lista com " + (json[k][listObjKey[0]]).length + " itens";
			}
			else {
				rVal = json[k][listObjKey[0]]
			}

		}
		else
			rVal = json[k]

		return { key: rKey, value: rVal }
	})
	return result
}
export function throwError(error) {
	//throw new Error(error)
	throw Error(error)
}

export const getTimeChat = (date) => {
	if (isToday(date))
		return moment(date).format('HH:mm')
	else if (isYesterday(date))
		return "ontem"
	else if (isMonth(date))
		return moment(date).format('DD-MM')
	else
		return moment(date).format('DD/MM/yyyy')
};


const isToday = (someDate) => {
	const today = new Date()
	return someDate.getDate() == today.getDate() &&
		someDate.getMonth() == today.getMonth() &&
		someDate.getFullYear() == today.getFullYear()
}

const isYesterday = (someDate) => {
	const today = new Date()
	return someDate.getDate() == today.getDate() - 1 &&
		someDate.getMonth() == today.getMonth() &&
		someDate.getFullYear() == today.getFullYear()
}

const isMonth = (someDate) => {
	const today = new Date()
	return someDate.getMonth() == today.getMonth() &&
		someDate.getFullYear() == today.getFullYear()
}

export const Alerts = (obj) => {
	if (!isNullOrEmptySpace(obj.alert) && !isNullOrEmptySpace(obj.alert.message))
		return (
			<UncontrolledAlert color={obj.alert.color}>
				{obj.alert.message}
			</UncontrolledAlert>
		)
}