import moment from 'moment';
import React from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import { Alert, Button, Card, CardBody, Col, FormFeedback, FormGroup, Input, Label, Row, UncontrolledAlert } from 'reactstrap';
import * as XLSX from "xlsx";
import { getUrlBackEnd, getUser } from '../../data/Auth';
import { ExecuteDataSource } from '../../framework/AstroNetREST';
import { isNullOrEmptySpace } from '../../framework/AstroNetUtility';
import "./drop.css"
class MessagesBatch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileNames: [],
            files: [],
            form: {
                whatsapp: {
                    label: "WhatsApp",
                    type: "select",
                    validation: {
                        isValid: true
                    }
                },
                dateSend: {
                    label: "Data Envio",
                    type: "date",
                    value: moment(Date()).format("YYYY-MM-DD"),
                    validation: {
                        isValid: true
                    }
                },
                timeSend: {
                    label: "Hora Envio",
                    type: "time",
                    value: moment(this.addHours(new Date(), 2)).format("hh:mm"),
                    validation: {
                        isValid: true
                    }
                },
            },
            alert: {
                color: "danger",
                message: null
            }
        }
    }
    addHours(date, hours) {
        date.setHours(date.getHours() + hours);

        return date;
    }
    onChange(ev) {
        const newProgram = this.state.form;
        const { name, value } = ev.currentTarget;
        console.log(value)
        if (!isNullOrEmptySpace(newProgram[name].type) && newProgram[name].type.toUpperCase() == "select") {
            try {
                newProgram[name].value = parseInt(value);
            }
            catch {
                newProgram[name].value = value;
            }
        }
        else
            newProgram[name].value = value;

        newProgram[name] = this.validation(newProgram[name])
        this.setState({ form: newProgram })
    }
    validation = (obj) => {
        let typesDecInt = ["INT", "DECIMAL"]
        if (isNullOrEmptySpace(obj.validation) || isNullOrEmptySpace(obj.validation.regex)) {
            obj.validation.isValid = true
            return obj
        }

        if (!isNullOrEmptySpace(obj.type) && typesDecInt.includes(obj.type.toUpperCase()) && isNullOrEmptySpace(obj.value)) {
            obj.validation.isValid = false
            return obj
        }

        switch (obj.validation.regex.constructor) {
            case String:
                if (!isNullOrEmptySpace(obj.type) &&
                    obj.type.toUpperCase() == "SELECT" &&
                    obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = (!isNullOrEmptySpace(obj.value) && parseInt(obj.value) > 0)
                else if (!isNullOrEmptySpace(obj.type) &&
                    typesDecInt.includes(obj.type.toUpperCase()) &&
                    obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = (!isNullOrEmptySpace(obj.value) && parseInt(obj.value) > 0)
                else if (obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = !isNullOrEmptySpace(obj.value)

                break;
            case RegExp:
                obj.validation.isValid = isNullOrEmptySpace(obj.value) ? false : !isNullOrEmptySpace(obj.validation.regex.exec(obj.value))
                break;
            default:
                obj.validation.isValid = true
        }
        return obj
    }

    import() {
        const { files } = this.state;
        const reader = new FileReader();
        reader.onload = (evt) => {
            const data = evt.target.result;
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetName = workbook.SheetNames[0]; // assumindo que você quer a primeira planilha
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet); // aqui você tem o array de objetos json com os dados da planilha
            let listJson = []
            jsonData.map((json) => {
                let bodys = []
                let headers = []
                let footers = []
                let phone = ""
                let template = ""
                let code = ""
                Object.keys(json).map(key => {
                    if (!isNullOrEmptySpace(key) && (key.toLowerCase().includes("fone") || key.toLowerCase().includes("celular") || key.toLowerCase().includes("phone") || key.toLowerCase().includes("whats")))
                        phone = json[key]
                    if (!isNullOrEmptySpace(key) && (key.toLowerCase().includes("template") || key.toLowerCase().includes("modelo")))
                        template = json[key]
                    if (!isNullOrEmptySpace(key) && (key.toLowerCase().includes("lingua")))
                        code = json[key]

                    if (!isNullOrEmptySpace(key) && key.toLowerCase().includes("cabe"))
                        bodys.push(json[key])
                    if (!isNullOrEmptySpace(key) && key.toLowerCase().includes("corpo"))
                        headers.push(json[key])
                    if (!isNullOrEmptySpace(key) && key.toLowerCase().includes("rodap"))
                        footers.push(json[key])
                })
                listJson.push({
                    ddi: 55,
                    phone: phone,
                    template: template,
                    code: code,
                    dateSend: moment(this.state.form.dateSend.value + " " + this.state.form.timeSend.value).toDate(),
                    parametersHeader: bodys,
                    parametersBody: headers,
                    parametersFooter: footers,
                    statusSend: false
                })

            })
            this.send(listJson)
        };
        reader.readAsBinaryString(files[0]);
    }

    send = (jsonData) => {
        let _this = this
        var obj = {
            DataSourceMethod: 'POST',
            DataSourceUrl: getUrlBackEnd() + 'send/message/batch',
            DataSourceData: jsonData,
            DataSourceHeader: {
                'content-type': 'application/json',
                'Authorization': this.state.form.whatsapp.value
            }
        }

        ExecuteDataSource(obj).then(function (response) {
            let { alert } = _this.state
            alert.color = "success"
            alert.message = "Enviado com Sucesso"
            _this.setState({ alert, fileNames: [], files: [], })

        }).catch(function (error) {
            let { alert } = _this.state
            console.error(error)
            alert.message = error.response.data.Message
            alert.color = "danger"
            _this.setState({ alert, fileNames: [], files: [], })
        });
    }

    render() {
        const Alerts = () => {
            if (!isNullOrEmptySpace(this.state.alert.message))
                return (
                    <UncontrolledAlert color={this.state.alert.color}>
                        {this.state.alert.message}
                    </UncontrolledAlert>
                )
        }
        const handleDrop = (acceptedFiles) => {
            let list = acceptedFiles.map(file => file.name)
            this.setState({ fileNames: list, files: acceptedFiles })
            console.log("handleDrop")
        }

        const disabledButton = this.state.files.length == 0
        const disabledDropzone = isNullOrEmptySpace(this.state.form.whatsapp.value);

        return (
            <Card>
                <CardBody>
                    <h3>Enviar mensagens em lote</h3>
                    {Alerts()}
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="whatsapp">
                                    {this.state.form.whatsapp.label}
                                </Label >
                                <Input invalid={!this.state.form.whatsapp.validation.isValid ? true : undefined}
                                    onChange={(e) => this.onChange(e)}
                                    name="whatsapp"
                                    value={this.state.form.whatsapp.value}
                                    validation={this.state.form.whatsapp.validation.regex}
                                    type="select">
                                    <option key={"0"} value={""}>
                                        Selecione
                                    </option>
                                    {
                                        getUser().whatsapps.map((x, i) => {
                                            return (
                                                <option key={i} value={x.tokenWhatsApp}>
                                                    {x.name}
                                                </option>
                                            )
                                        })
                                    }
                                </Input>
                                <FormFeedback invalid={!this.state.form.whatsapp.validation.isValid ? true : undefined}>
                                    {this.state.form.whatsapp.validation.error}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col>
                            <Alert color="primary">
                                <h4>Planilha de Modelo {' '}
                                    <a
                                        className="alert-link"
                                        href="../../../assets/modelo.xlsx"
                                        download
                                    >
                                        Clique aqui para Download
                                    </a>
                                </h4>
                            </Alert>
                        </Col>
                    </Row>
                    <Row className="row-cols-lg-auto g-3 align-items-center">
                        <Col>
                            <Label for="dateSend">
                                {this.state.form.dateSend.label}
                            </Label >
                            <Input invalid={!this.state.form.dateSend.validation.isValid ? true : undefined}
                                onChange={(e) => this.onChange(e)}
                                name="dateSend"
                                value={this.state.form.dateSend.value}
                                validation={this.state.form.dateSend.validation.regex}
                                type="date">
                            </Input>
                            <FormFeedback invalid={!this.state.form.whatsapp.validation.isValid ? true : undefined}>
                                {this.state.form.whatsapp.validation.error}
                            </FormFeedback>
                        </Col>
                        <Col>
                            <Label for="timeSend">
                                {this.state.form.timeSend.label}
                            </Label >
                            <Input invalid={!this.state.form.timeSend.validation.isValid ? true : undefined}
                                onChange={(e) => this.onChange(e)}
                                name="timeSend"
                                value={this.state.form.timeSend.value}
                                validation={this.state.form.timeSend.validation.regex}
                                type="time">
                            </Input>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Dropzone
                                    disabled={disabledDropzone}
                                    onDrop={handleDrop}
                                    accept={{
                                        'application/vnd.ms-excel': ['.xls', '.xlsx']
                                    }}
                                >
                                    {({
                                        getRootProps,
                                        getInputProps,
                                        isDragActive,
                                        isDragAccept,
                                        isDragReject
                                    }) => {

                                        const additionalClass = disabledDropzone ? "drop_disabled" : "";

                                        return (
                                            <div key={1} className="sectiondrop">
                                                <div
                                                    {...getRootProps({
                                                        className: `dropzone ${additionalClass}`
                                                    })}
                                                >
                                                    <input {...getInputProps()} accept=".csv" />
                                                    <h3 className="textdrop">Importar Planilha</h3>
                                                    <br />
                                                    <div className="archivodrop">
                                                        {this.state.fileNames.map((fileName, i) => (
                                                            <b><p key={i}>{fileName}</p></b>
                                                        ))}
                                                    </div>
                                                </div>

                                            </div>

                                        );
                                    }}
                                </Dropzone>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <FormGroup>
                            <Button disabled={disabledButton} color="deore" className={disabledButton ? "btn-secondary" : "btn-primary"} onClick={() => this.import()}>Enviar</Button>
                        </FormGroup>
                    </Row>
                </CardBody>
            </Card >

        )
    }
}

export default MessagesBatch;
