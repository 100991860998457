import React from "react";
import { Form, FormGroup, Label, Input, Button, Col, Row, Badge, Alert, FormFeedback } from "reactstrap"
import { setUser, setUrlBackEnd, getUrlBackEnd } from "../../data/Auth"
import { ExecuteDataSource } from "../../framework/AstroNetREST"
import { isNullOrEmptySpace } from "../../framework/AstroNetUtility";

class LoginPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            workspace: "",
            user: "",
            password: "",
            isworkspace: false,
            isuser: false,
            ispassword: false,
            token: "",
            error: ""
        }
    }

    changeField = (ev) => {
        const newProgram = { ...this.state };
        const { name, value } = ev.currentTarget;
        newProgram[name] = value;
        newProgram["is" + name] = !this.isValidate(ev.currentTarget.type, value) ? true : undefined
        this.setState(newProgram)
    }

    isValidate(type, value) {
        let validate
        let regexValidate;
        if (type.toUpperCase() === "EMAIL")
            regexValidate = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
        if (type.toUpperCase() === "PASSWORD")
            regexValidate = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$/)
        if (!isNullOrEmptySpace(regexValidate))
            validate = regexValidate.test(value)
        else
            validate = !isNullOrEmptySpace(value)

        return validate
    }

    onLogin() {
        var _this = this;
        var obj = {
            DataSourceMethod: "get",
            DataSourceUrl: 'https://workspace.astroti.com.br/workspace/' + this.state.workspace,
            DataSourceHeader: {
                "Authorization": "VhCO)&;:xJ#]>_tduR#ei>p:-Xe%zHl8hQ:N:h-%Q'V7IBH!m}(&=g9OQi3S{aN$.-30uSJ,+;SVwb^HZ0]j$!R^&l[>#O;=++;M<2 S*mDtd?futF;FL,x+4FRZy@q&"
            },
        }

        ExecuteDataSource(obj).then((resp) => {
            setUrlBackEnd(resp)
            _this.onLoginUser()
        }).catch((error) => {
            if (error.code === "ERR_NETWORK")
                _this.setState({ password: "", error: "Servidor Indisponivel! Tente mais tarde" })
        })
    }

    onLoginUser() {
        var _this = this;
        var obj = {
            DataSourceMethod: "POST",
            DataSourceUrl: getUrlBackEnd() + 'login',
            DataSourceHeader: {
                "Content-Type": "application/json",
                "accept": "text/plain"
            },
            DataSourceData: {
                Email: this.state.user,
                Password: this.state.password
            }
        }

        ExecuteDataSource(obj).then((resp) => {
            setUser(resp)
            window.location.href = "/"
        }).catch((error) => {
            if (error.code === "ERR_NETWORK")
                _this.setState({ password: "", error: "Servidor Indisponivel! Tente mais tarde" })
            else
                _this.setState({ password: "", error: "Usuario e senha inválidos" })
        })
    }

    render() {
        return (
            <Col className="container-fluid page-body-wrapper full-page-wrapper">
                <Col className="content-wrapper d-flex align-items-center auth px-0">
                    <Row className="row w-100 mx-0">
                        <Col className="col-lg-4 mx-auto">
                            <Col className="auth-form-light text-left py-5 px-4 px-sm-5">
                                <Col className="d-flex justify-content-center" style={{ marginBottom: "20px" }}>
                                    <img
                                        alt="logo"
                                        src="./image/logo.png"
                                        width="20%"
                                    />
                                </Col>
                                <h4>Bem vindos ao AstroWhatsApp</h4>
                                <Form>
                                    <FormGroup floating>
                                        <Input invalid={this.state.isworkspace}
                                            id="workspace"
                                            name="workspace"
                                            placeholder="workspace"
                                            type="text"
                                            value={this.state.workspace}
                                            onChange={(e) => this.changeField(e)}
                                        />
                                        <Label for="workspace">
                                            Workspace
                                        </Label>
                                        <FormFeedback invalid="true" >
                                            Informe o Workspace
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup floating>
                                        <Input invalid={this.state.isuser}
                                            id="user"
                                            name="user"
                                            placeholder="user"
                                            type="email"
                                            value={this.state.user}
                                            onChange={(e) => this.changeField(e)}
                                        />
                                        <Label for="user">
                                            E-mail
                                        </Label>
                                        <FormFeedback invalid="true" >
                                            Informe o Usuario
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup floating>
                                        <Input invalid={this.state.ispassword}
                                            id="password"
                                            name="password"
                                            type="password"
                                            value={this.state.password}
                                            onChange={(e) => this.changeField(e)}
                                        />
                                        <Label for="password">
                                            Senha
                                        </Label>
                                        <FormFeedback invalid="true" >
                                            Informe a senha
                                        </FormFeedback>
                                    </FormGroup>
                                </Form>
                                <br />
                                <FormGroup >
                                    <Alert color="danger" hidden={!this.state.error}>
                                        {this.state.error}
                                    </Alert>
                                </FormGroup>
                                <FormGroup>
                                    <Button color="deore" className="btn-primary" onClick={() => { this.onLogin() }}>Entrar</Button>
                                    <Col className="d-flex justify-content-end">
                                        <Badge color="secondary" href="#">
                                            Esqueci minha senha
                                        </Badge>
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Col>
                    </Row>
                </Col>
            </Col>
        )
    }
}

export default LoginPage