import React from "react"
import {
    Form, FormGroup,
    Label, Input, FormFeedback, UncontrolledAlert,
    Button, Col, Row, ModalHeader, Modal, ModalBody
} from "reactstrap"

import { convertToBase64Encode, isNullOrEmptySpace } from "../../framework/AstroNetUtility"
import PropTypes from 'prop-types';
import { ExecuteDataSourceSync, ExecuteDataSource } from "../../framework/AstroNetREST";
import { getUrlBackEnd, getUser } from "../../data/Auth"
import InputMask from "react-input-mask"
import { TypeUser } from "../user/ComponentsUser";
import StatusTalkHome from "./StatusTalkHome";

class ClientPhoneHandle extends React.Component {

    static propTypes = {
        value: PropTypes.func,
    }

    constructor(props) {
        super(props)
        this.state = {
            form: {
                tokenWhatsApp: {
                    label: "KeyAstro",
                    validation: {
                        isValid: true
                    }
                },
                name: {
                    label: "Nome",
                    validation: {
                        error: "Nome Invalido",
                        regex: "Required",
                        isValid: true
                    }
                },
                phone: {
                    label: "Telefone",
                    mask: "(99)9 9999-9999",
                    validation: {
                        error: "Telefone Invalido",
                        regex: "Required",
                        isValid: true
                    }
                },
                status: {
                    label: "Status",
                    type: "select",
                    validation: {
                        isValid: true
                    }
                },
                whatsAppBusinessPhoneNumberId: {
                    label: "WhatsApp Business - Phone Number Id",
                    validation: {
                        error: "PhoneNumber Id",
                        regex: "Required",
                        isValid: true
                    }
                },
                whatsAppBusinessAccessToken: {
                    label: "WhatsApp Business - Access Token",
                    validation: {
                        error: "Access Token Invalido",
                        regex: "Required",
                        isValid: true
                    }
                },
                wabaId: {
                    label: "WhatsApp Business - WABA",
                    placeholder: "Id da Conta WhatsApp no Meta Buiness (Configurações de Negocio/Contas WhatsApp)",
                    validation: {
                        error: "WABA Invalido",
                        regex: "Required",
                        isValid: true
                    }
                },
                webHook: {
                    label: "WhatsApp Business - Url WebHook",
                    validation: {
                        error: "Url Invalida",
                        isValid: true
                    }
                },
                webHookToken: {
                    label: "WhatsApp Business - Url WebHook Token(Palavra Secreta)",
                    validation: {
                        error: "Token Invalido",
                        isValid: true
                    }
                },
                webHookClient: {
                    label: "Seu WebHook - Url(Metodo Post) que irá ser notificado cada fez que receber ou enviar MENSAGENS",
                    validation: {
                        isValid: true
                    }
                },
                externalIdDefaultContact: {
                    label: "External Id Default",
                    validation: {
                        isValid: true
                    }
                },
                externalJsonDefaultContact: {
                    label: "External JSON Default",
                    validation: {
                        isValid: true
                    }
                },
                timeReturnExternalDefault: {
                    label: "Tempo de inatividade de uma conversa e retorno para o External Default(Horas)",
                    validation: {
                        isValid: true
                    }
                },
                packageLimiteMessage: {
                    label: "Quantidade Maxima de mensagens permitidas/mes",
                    validation: {
                        isValid: true
                    }
                },
            },
            isOpenModalStatus: false,
            alert: {
                color: "danger",
                message: null
            }
        }
    }

    componentWillMount() {
        if (getUser().type !== TypeUser.MASTER)
            window.location.href = '/nopermission'

        const { client } = this.props
        const { form } = this.state


        var elements = Object.keys(form)
        elements.map(x => {
            if (x === "externalJsonDefaultContact")
                form[x].value = isNullOrEmptySpace(client[x]) ? "" : JSON.stringify(client[x])
            else if (x === "status")
                form[x].value = client[x] ? 1 : 0
            else
                form[x].value = client[x]
        })
        this.setState({ form })
    }

    onChange = (ev) => {
        const newProgram = this.state.form;
        const { name, value } = ev.currentTarget;

        if (!isNullOrEmptySpace(newProgram[name].type) && newProgram[name].type.toUpperCase() == "SELECT") {
            try {
                newProgram[name].value = parseInt(value);
            }
            catch {
                newProgram[name].value = value;
            }
        }
        else
            newProgram[name].value = value;

        newProgram[name] = this.validation(newProgram[name])
        this.setState({ form: newProgram })
    }

    validation = (obj) => {
        let typesDecInt = ["INT", "DECIMAL"]
        if (isNullOrEmptySpace(obj.validation) || isNullOrEmptySpace(obj.validation.regex)) {
            obj.validation.isValid = true
            return obj
        }

        if (!isNullOrEmptySpace(obj.type) && typesDecInt.includes(obj.type.toUpperCase()) && isNullOrEmptySpace(obj.value)) {
            obj.validation.isValid = false
            return obj
        }

        switch (obj.validation.regex.constructor) {
            case String:
                if (!isNullOrEmptySpace(obj.type) &&
                    obj.type.toUpperCase() == "SELECT" &&
                    obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = (!isNullOrEmptySpace(obj.value) && parseInt(obj.value) > 0)
                else if (!isNullOrEmptySpace(obj.type) &&
                    typesDecInt.includes(obj.type.toUpperCase()) &&
                    obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = (!isNullOrEmptySpace(obj.value) && parseInt(obj.value) > 0)
                else if (obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = !isNullOrEmptySpace(obj.value)

                break;
            case RegExp:
                obj.validation.isValid = !isNullOrEmptySpace(obj.validation.regex.exec(obj.value))
                break;
            default:
                obj.validation.isValid = true
        }
        return obj
    }

    validationForm = () => {
        const newProgram = this.state.form;
        var result = true;
        var elements = Object.keys(this.state.form)
        elements.forEach(name => {
            newProgram[name] = this.validation(newProgram[name])
            if (!newProgram[name].validation.isValid)
                result = false
        })
        this.setState({ form: newProgram })

        return result;
    }

    cancel = (event) => {
        const { value } = this.props;
        value(
            event
        )
    }

    save = (form) => {
        let _this = this
        if (!this.validationForm()) {
            return
        }
        form = this.state.form

        var elements = Object.keys(form)
        var objElement = {};
        elements.map(x => {
            if (!isNullOrEmptySpace(form[x].type) && form[x].type.toLocaleLowerCase() == "decimal" && form[x].value.constructor == String && !isNullOrEmptySpace(form[x].value))
                form[x].value = form[x].value.replace(".", "").replace(",", ".")

            if (x.toLocaleLowerCase() !== "validation")
                objElement[x] = form[x].value

        })

        if (!isNullOrEmptySpace(objElement.externalJsonDefaultContact))
            objElement.externalJsonDefaultContact = JSON.parse(objElement.externalJsonDefaultContact)
        else
            objElement.externalJsonDefaultContact = null

        if (isNullOrEmptySpace(objElement.clientId))
            objElement.clientId = this.props.client.clientId

        var obj = {
            DataSourceMethod: 'POST',
            DataSourceUrl: getUrlBackEnd() + 'whatsapp/save',
            DataSourceData: objElement,
            DataSourceHeader: {
                'content-type': 'application/json',
                'Authorization': getUser().token
            }
        }

        ExecuteDataSource(obj).then(function (response) {
            let { alert } = _this.state
            alert.color = "success"
            alert.message = "Salvo com Sucesso"
            _this.setState({ alert })

        }).catch(function (error) {
            let { alert } = _this.state
            console.error(error)
            alert.message = error.response.data.Message
            alert.color = "danger"
            _this.setState({ alert })
        });
    }

    getHash(alias) {
        var obj = {
            DataSourceMethod: "GET",
            DataSourceUrl: getUrlBackEnd() + 'client/hash/' + alias,
            DataSourceHeader: {
                "Content-Type": "application/json",
                "accept": "text/plain"
            },
        }
        var resp = ExecuteDataSourceSync(obj)
        return resp
    }

    getKey(alias) {
        var obj = {
            DataSourceMethod: "GET",
            DataSourceUrl: getUrlBackEnd() + 'client/key/' + alias,
            DataSourceHeader: {
                "Content-Type": "application/json",
                "accept": "text/plain"
            },
        }
        var resp = ExecuteDataSourceSync(obj)
        return resp
    }

    onClickHash() {
        let { form } = this.state
        if (isNullOrEmptySpace(this.state.form.alias.value)) {
            form.keySaltHash.validation.error = "Abreviatura Invalido"
            form.keySaltHash.validation.isValid = false;
            this.setState({ form })
            return
        }

        var resp = this.getHash(this.state.form.alias.value)

        if (resp.StatusCode === 599) {
            form.keySaltHash.value = "";
            form.keySaltHash.validation.error = resp.Message;
            form.keySaltHash.validation.isValid = false;
        }
        else {

            form.keySaltHash.value = resp;
            form.keySaltHash.validation.isValid = true;
        }

        this.setState({ form })

    }

    onClickKey() {
        let { form } = this.state
        if (isNullOrEmptySpace(this.state.form.alias.value)) {
            form.keyClient.validation.error = "Abreviatura Invalido"
            form.keyClient.validation.isValid = false;
            this.setState({ form })
            return
        }
        var resp = this.getKey(this.state.form.alias.value)

        if (resp.StatusCode === 599) {
            form.keyClient.value = "";
            form.keyClient.validation.error = resp.Message
            form.keyClient.validation.isValid = false;
        }
        else {
            form.keyClient.value = resp;
            form.keyClient.validation.isValid = true;
        }

        this.setState({ form })
    }

    toggle() {
        this.setState(prevState => ({
            isOpenModalStatus: !prevState.isOpenModalStatus
        }));
    }


    render() {
        this.state.form.status.value = isNullOrEmptySpace(this.state.form.status.value) ? 1 : this.state.form.status.value

        const Alerts = () => {
            if (!isNullOrEmptySpace(this.state.alert.message))
                return (
                    <UncontrolledAlert color={this.state.alert.color}>
                        {this.state.alert.message}
                    </UncontrolledAlert>
                )
        }

        const getWebHookSecret = () => {
            if (!isNullOrEmptySpace(this.state.form.webHookToken.value))
                return this.state.form.webHookToken.value

            if (isNullOrEmptySpace(this.state.form.tokenWhatsApp.value))
                return ""

            let result = this.state.form.tokenWhatsApp.value
            return result
        }
        const getWebHookUrl = () => {
            if (!isNullOrEmptySpace(this.state.form.webHook.value))
                return this.state.form.webHook.value

            if (isNullOrEmptySpace(this.state.form.tokenWhatsApp.value))
                return ""

            let result = `https://whatsapp.astroti.com.br/wp/${convertToBase64Encode(this.state.form.tokenWhatsApp.value)}/receive`
            return result
        }


        return (
            <>
                <h6> <h4 className="card-title">WhatsApp: {this.state.form.name.value}-{this.state.form.phone.value} </h4></h6>
                <br />
                {Alerts()}
                <Form className="forms-sample">
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="id">
                                    {this.state.form.tokenWhatsApp.label}
                                </Label >
                                <Input invalid={!this.state.form.tokenWhatsApp.validation.isValid ? true : undefined}
                                    onChange={(e) => this.onChange(e)}
                                    name="id"
                                    value={this.state.form.tokenWhatsApp.value}
                                    validation={this.state.form.tokenWhatsApp.validation.regex}
                                    disabled
                                />
                                <FormFeedback invalid={!this.state.form.tokenWhatsApp.validation.isValid ? true : undefined}>
                                    {this.state.form.tokenWhatsApp.validation.error}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="name">
                                    {this.state.form.name.label}
                                </Label >
                                <Input invalid={!this.state.form.name.validation.isValid ? true : undefined}
                                    onChange={(e) => this.onChange(e)}
                                    name="name"
                                    value={this.state.form.name.value}
                                    validation={this.state.form.name.validation.regex}
                                />
                                <FormFeedback invalid={!this.state.form.name.validation.isValid ? true : undefined}>
                                    {this.state.form.name.validation.error}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="phone">
                                    {this.state.form.phone.label}
                                </Label >
                                <Input invalid={!this.state.form.phone.validation.isValid ? true : undefined}
                                    onChange={(e) => this.onChange(e)}
                                    name="phone"
                                    value={this.state.form.phone.value}
                                    validation={this.state.form.phone.validation.regex}
                                    tag={InputMask}
                                    mask={this.state.form.phone.mask}
                                    maskChar=" "
                                />
                                <FormFeedback invalid={!this.state.form.phone.validation.isValid ? true : undefined}>
                                    {this.state.form.phone.validation.error}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="status">
                                    {this.state.form.status.label}
                                </Label >
                                <Input invalid={!this.state.form.status.validation.isValid ? true : undefined}
                                    onChange={(e) => this.onChange(e)}
                                    name="status"
                                    value={this.state.form.status.value}
                                    validation={this.state.form.status.validation.regex}
                                    type="select">
                                    <option key={1} value={1}>
                                        Ativo
                                    </option>
                                    <option key={0} value={0}>
                                        Inativo
                                    </option>
                                </Input>
                                <FormFeedback invalid={!this.state.form.status.validation.isValid ? true : undefined}>
                                    {this.state.form.status.validation.error}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="webHookClient">
                                    {this.state.form.webHookClient.label}
                                </Label >
                                <Input invalid={!this.state.form.webHookClient.validation.isValid ? true : undefined}
                                    onChange={(e) => this.onChange(e)}
                                    name="webHookClient"
                                    value={this.state.form.webHookClient.value}
                                    validation={this.state.form.webHookClient.validation.regex}
                                    type="textarea"
                                    row="10"
                                    style={{ height: "100px", lineHeight: "normal" }}
                                />
                                <FormFeedback invalid={!this.state.form.webHookClient.validation.isValid ? true : undefined}>
                                    {this.state.form.webHookClient.validation.error}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="externalIdDefaultContact">
                                    {this.state.form.externalIdDefaultContact.label}
                                </Label >
                                <Input invalid={!this.state.form.externalIdDefaultContact.validation.isValid ? true : undefined}
                                    onChange={(e) => this.onChange(e)}
                                    name="externalIdDefaultContact"
                                    value={this.state.form.externalIdDefaultContact.value}
                                    validation={this.state.form.externalIdDefaultContact.validation.regex}
                                />
                                <FormFeedback invalid={!this.state.form.externalIdDefaultContact.validation.isValid ? true : undefined}>
                                    {this.state.form.externalIdDefaultContact.validation.error}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="externalJsonDefaultContact">
                                    {this.state.form.externalJsonDefaultContact.label}
                                </Label >
                                <Input invalid={!this.state.form.externalJsonDefaultContact.validation.isValid ? true : undefined}
                                    onChange={(e) => this.onChange(e)}
                                    name="externalJsonDefaultContact"
                                    value={this.state.form.externalJsonDefaultContact.value}
                                    validation={this.state.form.externalJsonDefaultContact.validation.regex}
                                    type="textarea"
                                    row="10"
                                    style={{ height: "100px", lineHeight: "normal" }}
                                />
                                <FormFeedback invalid={!this.state.form.externalJsonDefaultContact.validation.isValid ? true : undefined}>
                                    {this.state.form.externalJsonDefaultContact.validation.error}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="timeReturnExternalDefault">
                                    {this.state.form.timeReturnExternalDefault.label}
                                </Label >
                                <Input invalid={!this.state.form.timeReturnExternalDefault.validation.isValid ? true : undefined}
                                    onChange={(e) => this.onChange(e)}
                                    name="timeReturnExternalDefault"
                                    value={this.state.form.timeReturnExternalDefault.value}
                                    type="number"
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="whatsAppBusinessPhoneNumberId">
                                    {this.state.form.whatsAppBusinessPhoneNumberId.label}
                                </Label >
                                <Input invalid={!this.state.form.whatsAppBusinessPhoneNumberId.validation.isValid ? true : undefined}
                                    onChange={(e) => this.onChange(e)}
                                    name="whatsAppBusinessPhoneNumberId"
                                    value={this.state.form.whatsAppBusinessPhoneNumberId.value}
                                    validation={this.state.form.whatsAppBusinessPhoneNumberId.validation.regex}
                                />
                                <FormFeedback invalid={!this.state.form.whatsAppBusinessPhoneNumberId.validation.isValid ? true : undefined}>
                                    {this.state.form.whatsAppBusinessPhoneNumberId.validation.error}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="whatsAppBusinessAccessToken">
                                    {this.state.form.whatsAppBusinessAccessToken.label}
                                </Label >
                                <Input invalid={!this.state.form.whatsAppBusinessAccessToken.validation.isValid ? true : undefined}
                                    onChange={(e) => this.onChange(e)}
                                    name="whatsAppBusinessAccessToken"
                                    value={this.state.form.whatsAppBusinessAccessToken.value}
                                    validation={this.state.form.whatsAppBusinessAccessToken.validation.regex}
                                    type="textarea"
                                    row="10"
                                    style={{ height: "100px", lineHeight: "normal" }}
                                />
                                <FormFeedback invalid={!this.state.form.whatsAppBusinessAccessToken.validation.isValid ? true : undefined}>
                                    {this.state.form.whatsAppBusinessAccessToken.validation.error}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="wabaId">
                                    {this.state.form.wabaId.label}
                                </Label >
                                <Input invalid={!this.state.form.wabaId.validation.isValid ? true : undefined}
                                    onChange={(e) => this.onChange(e)}
                                    name="wabaId"
                                    value={this.state.form.wabaId.value}
                                    validation={this.state.form.wabaId.validation.regex}
                                    placeholder={this.state.form.wabaId.placeholder}
                                />
                                <FormFeedback invalid={!this.state.form.wabaId.validation.isValid ? true : undefined}>
                                    {this.state.form.wabaId.validation.error}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="webHook">
                                    {this.state.form.webHook.label}
                                </Label >
                                <Input invalid={!this.state.form.webHook.validation.isValid ? true : undefined}
                                    onChange={(e) => this.onChange(e)}
                                    name="webHook"
                                    value={getWebHookUrl()}
                                    validation={this.state.form.webHook.validation.regex}
                                    disabled
                                    type="textarea"
                                    row="10"
                                    style={{ height: "100px", lineHeight: "normal" }}

                                />
                                <FormFeedback invalid={!this.state.form.webHook.validation.isValid ? true : undefined}>
                                    {this.state.form.webHook.validation.error}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="webHookToken">
                                    {this.state.form.webHookToken.label}
                                </Label >
                                <Input invalid={!this.state.form.webHookToken.validation.isValid ? true : undefined}
                                    onChange={(e) => this.onChange(e)}
                                    name="webHookToken"
                                    value={getWebHookSecret()}
                                    validation={this.state.form.webHookToken.validation.regex}
                                    disabled
                                />
                                <FormFeedback invalid={!this.state.form.webHookToken.validation.isValid ? true : undefined}>
                                    {this.state.form.webHookToken.validation.error}
                                </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="packageLimiteMessage">
                                    {this.state.form.packageLimiteMessage.label}
                                </Label >
                                <Input invalid={!this.state.form.packageLimiteMessage.validation.isValid ? true : undefined}
                                    onChange={(e) => this.onChange(e)}
                                    name="packageLimiteMessage"
                                    value={this.state.form.packageLimiteMessage.value}
                                    type="number"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Button onClick={() => this.setState({ isOpenModalStatus: true })}>Status de Conversa</Button>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <FormGroup>
                            <Button className="me-2" onClick={() => this.cancel()}>Cancelar</Button>
                            <Button color="deore" className="btn-primary" onClick={() => this.save()}>Salvar</Button>

                        </FormGroup>
                    </Row>
                </Form>
                <Modal isOpen={this.state.isOpenModalStatus} size="lg">
                    <ModalHeader toggle={() => this.toggle()}>
                        <span>Status de Conversar</span>
                    </ModalHeader>
                    <ModalBody>
                        <StatusTalkHome tokenWhatsApp={this.state.form.tokenWhatsApp.value} />
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

ClientPhoneHandle.defaultProps = {
    props: {},
    value: () => null,
};

ClientPhoneHandle.propTypes = {
    props: PropTypes.object,
    value: PropTypes.func,
};

export default ClientPhoneHandle;