import React from "react"
import {
    Card, CardBody,
    UncontrolledAlert, FormGroup, Input, Button, Row, Col, Label, CardFooter
} from "reactstrap"
import DataTable from "react-data-table-component"
import { isNullOrEmptySpace, paginationComponentOptions } from "../../framework/AstroNetUtility"
import UserHandle from "./UserHandle"
import { ExecuteDataSource } from "../../framework/AstroNetREST";
import { getUrlBackEnd, getUser } from "../../data/Auth"
import { getTypeUser, TypeUser } from "./ComponentsUser"

class UserHome extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            alert: {
                color: "danger",
                message: null
            }
        }
    }

    onChange = (ev) => {
        const newProgram = this.state;
        const { name, value } = ev.currentTarget;
        newProgram[name] = value;
        this.filter(value)
        this.setState({ newProgram })
    }

    filter(value) {
        const newProgram = this.state;
        if (isNullOrEmptySpace(value))
            newProgram.listNew = this.state.list

        if (!isNullOrEmptySpace(this.state.list) && !isNullOrEmptySpace(value)) {
            newProgram.listNew = this.state.list.filter(x => x.name.toUpperCase().includes(value.toUpperCase()) ||
                x.email.toUpperCase().includes(value.toUpperCase()))
        }
        this.setState({ newProgram })
    }

    customStyles = () => {
        return {
            headRow: {
                style: {
                    border: 'none',

                },
            },
            headCells: {
                style: {
                    color: '#202124',
                    fontSize: '14px',

                },
            },
            rows: {
                highlightOnHoverStyle: {
                    backgroundColor: 'rgb(230, 244, 244)',
                    borderBottomColor: '#FFFFFF',
                    borderRadius: '25px',
                    outline: '1px solid #FFFFFF',

                },
            },
            pagination: {
                style: {
                    border: 'none',

                },
            }

        }
    }

    onClickRow = (row) => {
        this.setState({
            selected: row
        })
    }

    handle = () => {
        this.recharge();
        this.setState({
            selected: null
        })
    }

    new = () => {
        this.setState({
            selected: 0
        })
    }

    componentWillMount() {
        if (getUser().type === TypeUser.USER)
            window.location.href = '/nopermission'

        this.recharge();
    }

    recharge() {
        let _this = this;

        var obj = {
            DataSourceMethod: 'GET',
            DataSourceUrl: getUrlBackEnd() + 'user/get',
            DataSourceHeader: {
                'content-type': 'application/json',
                'Authorization': getUser().token
            }
        }

        ExecuteDataSource(obj).then(function (response) {
            _this.setState({ list: response, listNew: response })
            if (!isNullOrEmptySpace(_this.state.filter))
                _this.filter(_this.state.filter)

        }).catch(function (error) {
            let { alert } = _this.state
            console.error(error)
            alert.message = error.response.data.Message
            alert.color = "danger"
            _this.setState({ alert })
        });
    }


    lister = () => {
        const columns = [
            {
                name: 'Nome',
                selector: row => row.name,
                sortable: true,
                style: {
                    color: 'rgba(0,0,0,.54)',
                },
            },
            {
                name: 'Email',
                selector: row => row.email,
                sortable: true,
                style: {
                    color: 'rgba(0,0,0,.54)',
                },
            },
            {
                name: 'Tipo',
                selector: row => getTypeUser(row.type),
                sortable: true,
                style: {
                    color: 'rgba(0,0,0,.54)',
                },
            },
        ];

        return (
            <DataTable
                columns={columns}
                data={this.state.listNew}
                customStyles={this.customStyles}
                pagination
                paginationComponentOptions={paginationComponentOptions()}
                noDataComponent="Não encontrado"
                highlightOnHover
                pointerOnHover
                onRowClicked={(row) => this.onClickRow(row)}
                actions={<Button color="deore" className="btn-primary" onClick={() => this.new()}>Novo</Button>}
            />
        )
    }

    render = () => {

        const Alerts = () => {
            if (!isNullOrEmptySpace(this.state.alert.message))
                return (
                    <UncontrolledAlert color={this.state.alert.color}>
                        {this.state.alert.message}
                    </UncontrolledAlert>
                )
        }


        if (!isNullOrEmptySpace(this.state.selected))
            return (<UserHandle user={this.state.selected} value={() => this.handle()} />)
        else
            return (
                <>
                    <Row>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <h4 className="card-title">Cadastro Usuário</h4>
                                    </Col>
                                </Row>
                                <FormGroup floating>

                                    <Input
                                        id="filter"
                                        name="filter"
                                        placeholder="Usuário"
                                        value={this.state.filter}
                                        onChange={(e) => this.onChange(e)}
                                    />
                                    <Label for="filter">
                                        Usuário
                                    </Label>
                                </FormGroup>
                                <Row>
                                    {Alerts()}
                                </Row>
                            </CardBody>
                        </Card>
                    </Row>
                    <br />
                    <Row>
                        <Card>
                            <CardBody>
                                {this.lister()}
                            </CardBody>
                        </Card>
                    </Row>
                </>
            )
    }
}

export default UserHome;