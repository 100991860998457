import React from "react"
import {
    Card, CardBody,
    Form, FormGroup,
    Label, Input, FormFeedback, UncontrolledAlert,
    Button, Col, Row,
} from "reactstrap"

import { convertToBase64Encode, isNullOrEmptySpace } from "../../framework/AstroNetUtility"
import PropTypes from 'prop-types';
import { ExecuteDataSource } from "../../framework/AstroNetREST";
import { getUrlBackEnd, getUser } from "../../data/Auth"
import { getTypeUser, TypeUser } from "./ComponentsUser";

class UserHandle extends React.Component {

    static propTypes = {
        value: PropTypes.func,
    }

    constructor(props) {
        super(props)
        this.state = {
            form: {
                token:{
                    validation:{
                        isValid: true
                    }
                },
                name: {
                    label: "Nome",
                    validation: {
                        error: "Nome Invalido",
                        regex: "Required",
                        isValid: true
                    }
                },
                email: {
                    label: "E-mail",
                    validation: {
                        error: "E-mail invalido",
                        regex: new RegExp("^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?"),
                        isValid: true
                    }
                },
                tokenClient: {
                    label: "Cliente",
                    validation: {
                        error: "Cliente Invalido",
                        regex: "Required",
                        isValid: true
                    }
                },
                type: {
                    label: "Tipo",
                    validation: {
                        error: "Tipo Obrigatorio",
                        regex: "Required",
                        isValid: true
                    }
                },
                password: {
                    label: "Senha",
                    validation: {
                        isValid: true
                    }
                }
            },
            listClients: [],
            alert: {
                color: "danger",
                message: null
            }
        }
    }

    componentWillMount() {
        const { user } = this.props
        const { form } = this.state
        var elements = Object.keys(form)
        elements.map(x => {
            form[x].value = user[x]
        })
        if (isNullOrEmptySpace(form.tokenClient.value) && getUser().type != TypeUser.MASTER){
            
            form.tokenClient.value = getUser().tokenClient
        }

        this.getClientAll()
        this.setState({ form })
    }

    getClientAll() {
        let _this = this;

        var obj = {
            DataSourceMethod: 'GET',
            DataSourceUrl: getUrlBackEnd() + 'client/all',
            DataSourceHeader: {
                'content-type': 'application/json',
                'Authorization': getUser().token
            }
        }

        ExecuteDataSource(obj).then(function (response) {
            response.map(x => x.id = convertToBase64Encode(x.id))
            _this.setState({ listClients: response, })

        }).catch(function (error) {
            let { alert } = _this.state
            console.error(error)
            alert.message = error.response.data.Message
            alert.color = "danger"
            _this.setState({ alert })
        });
    }

    getClient(tokenClient) {
        let _this = this;

        var obj = {
            DataSourceMethod: 'GET',
            DataSourceUrl: getUrlBackEnd() + "client/get/" + tokenClient,
            DataSourceHeader: {
                'content-type': 'application/json',
                'Authorization': getUser().token
            }
        }

        ExecuteDataSource(obj).then(function (response) {


        }).catch(function (error) {
            let { alert } = _this.state
            console.error(error)
            alert.message = error.response.data.Message
            alert.color = "danger"
            _this.setState({ alert })
        });

    }

    onChange = (ev) => {
        const newProgram = this.state.form;
        const { name, value } = ev.currentTarget;

        if (!isNullOrEmptySpace(newProgram[name].type) && newProgram[name].type.toUpperCase() == "select") {
            try {
                newProgram[name].value = parseInt(value);
            }
            catch {
                newProgram[name].value = value;
            }
        }
        else
            newProgram[name].value = value;

        newProgram[name] = this.validation(newProgram[name])
        this.setState({ form: newProgram })
    }

    validation = (obj) => {
        let typesDecInt = ["INT", "DECIMAL"]
        if (isNullOrEmptySpace(obj.validation) || isNullOrEmptySpace(obj.validation.regex)) {
            obj.validation.isValid = true
            return obj
        }

        if (!isNullOrEmptySpace(obj.type) && typesDecInt.includes(obj.type.toUpperCase()) && isNullOrEmptySpace(obj.value)) {
            obj.validation.isValid = false
            return obj
        }

        switch (obj.validation.regex.constructor) {
            case String:
                if (!isNullOrEmptySpace(obj.type) &&
                    obj.type.toUpperCase() == "SELECT" &&
                    obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = (!isNullOrEmptySpace(obj.value) && parseInt(obj.value) > 0)
                else if (!isNullOrEmptySpace(obj.type) &&
                    typesDecInt.includes(obj.type.toUpperCase()) &&
                    obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = (!isNullOrEmptySpace(obj.value) && parseInt(obj.value) > 0)
                else if (obj.validation.regex.toUpperCase() === "REQUIRED")
                    obj.validation.isValid = !isNullOrEmptySpace(obj.value)

                break;
            case RegExp:
                obj.validation.isValid = isNullOrEmptySpace(obj.value)? false: !isNullOrEmptySpace(obj.validation.regex.exec(obj.value))
                break;
            default:
                obj.validation.isValid = true
        }
        return obj
    }

    validationForm = () => {
        const newProgram = this.state.form;
        var result = true;
        var elements = Object.keys(this.state.form)
        elements.forEach(name => {
            newProgram[name] = this.validation(newProgram[name])
            if (!newProgram[name].validation.isValid)
                result = false
        })
        this.setState({ form: newProgram })

        return result;
    }

    cancel = (event) => {
        const { value } = this.props;
        value(
            event
        )
    }

    save = (form) => {
        let _this = this
        if (!this.validationForm()) {
            return
        }
        form = this.state.form

        var elements = Object.keys(form)
        var objElement = {};
        elements.map(x => {
            if (!isNullOrEmptySpace(form[x].type) && form[x].type.toLocaleLowerCase() == "decimal" && form[x].value.constructor == String && !isNullOrEmptySpace(form[x].value))
                form[x].value = form[x].value.replace(".", "").replace(",", ".")
            if (!isNullOrEmptySpace(form[x].type) && form[x].type.toLocaleLowerCase() == "select")
                form[x].value = parseInt(form[x].value);
            if (x.toLocaleLowerCase() !== "validation")
                objElement[x] = form[x].value

        })

        var obj = {
            DataSourceMethod: 'POST',
            DataSourceUrl: getUrlBackEnd() + 'user/save',
            DataSourceData: objElement,
            DataSourceHeader: {
                'content-type': 'application/json',
                'Authorization': getUser().token
            }
        }

        ExecuteDataSource(obj).then(function (response) {
            let { alert } = _this.state
            alert.color = "success"
            alert.message = "Salvo com Sucesso"
            _this.setState({ alert })

        }).catch(function (error) {
            let { alert } = _this.state
            console.error(error)
            alert.message = error.response.data.Message
            alert.color = "danger"
            _this.setState({ alert })
        });
    }

    render() {

        const Alerts = () => {
            if (!isNullOrEmptySpace(this.state.alert.message))
                return (
                    <UncontrolledAlert color={this.state.alert.color}>
                        {this.state.alert.message}
                    </UncontrolledAlert>
                )
        }

        return (
            <Card>
                <CardBody>
                    <h4 className="card-title">Cadastro Usuário</h4>
                    <h6>{this.props.user.token}</h6>
                    <br />
                    {Alerts()}
                    <Form className="forms-sample">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="name">
                                        {this.state.form.name.label}
                                    </Label >
                                    <Input invalid={!this.state.form.name.validation.isValid ? true : undefined}
                                        onChange={(e) => this.onChange(e)}
                                        name="name"
                                        value={this.state.form.name.value}
                                        validation={this.state.form.name.validation.regex}
                                    />
                                    <FormFeedback invalid={!this.state.form.name.validation.isValid ? true : undefined}>
                                        {this.state.form.name.validation.error}
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">
                                        {this.state.form.email.label}
                                    </Label >
                                    <Input invalid={!this.state.form.email.validation.isValid ? true : undefined}
                                        onChange={(e) => this.onChange(e)}
                                        name="email"
                                        value={this.state.form.email.value}
                                        validation={this.state.form.email.validation.regex} />
                                    <FormFeedback invalid={!this.state.form.email.validation.isValid ? true : undefined}>
                                        {this.state.form.email.validation.error}
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="type">
                                        {this.state.form.type.label}
                                    </Label >
                                    <Input invalid={!this.state.form.type.validation.isValid ? true : undefined}
                                        onChange={(e) => this.onChange(e)}
                                        name="type"
                                        value={this.state.form.type.value}
                                        validation={this.state.form.type.validation.regex}
                                        type="select">
                                        <option key={0} value={0}>
                                            Selecione
                                        </option>
                                        {
                                            getUser().type == TypeUser.MASTER ?
                                                <option key={1} value={1}>
                                                    {getTypeUser(1)}
                                                </option> :
                                                ""
                                        }   
                                        <option key={2} value={2}>
                                            {getTypeUser(2)}
                                        </option>
                                        <option key={3} value={3}>
                                            {getTypeUser(3)}
                                        </option>
                                    </Input>
                                    <FormFeedback invalid={!this.state.form.type.validation.isValid ? true : undefined}>
                                        {this.state.form.type.validation.error}
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="tokenClient">
                                        {this.state.form.tokenClient.label}
                                    </Label >
                                    <Input invalid={!this.state.form.tokenClient.validation.isValid ? true : undefined}
                                        onChange={(e) => this.onChange(e)}
                                        name="tokenClient"
                                        value={this.state.form.tokenClient.value}
                                        validation={this.state.form.tokenClient.validation.regex}
                                        disabled={getUser().type != TypeUser.MASTER}
                                        type="select">
                                        <option value={0}>
                                            Selecione
                                        </option>
                                        {this.state.listClients.map((x, i) => {
                                            return (
                                                <option key={i} value={x.id}>
                                                    {x.name}
                                                </option>
                                            )
                                        })}
                                    </Input>
                                    <FormFeedback invalid={!this.state.form.tokenClient.validation.isValid ? true : undefined}>
                                        {this.state.form.tokenClient.validation.error}
                                    </FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="password">
                                        {this.state.form.password.label}
                                    </Label >
                                    <Input invalid={!this.state.form.password.validation.isValid ? true : undefined}
                                        onChange={(e) => this.onChange(e)}
                                        name="password"
                                        type="password"
                                        value={this.state.form.password.value}
                                        validation={this.state.form.password.validation.regex} />
                                    <FormFeedback invalid={!this.state.form.password.validation.isValid ? true : undefined}>
                                        {this.state.form.password.validation.error}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <FormGroup>
                                <Button className="me-2" onClick={() => this.cancel()}>Cancelar</Button>
                                <Button color="deore" className="btn-primary" onClick={() => this.save()}>Salvar</Button>

                            </FormGroup>
                        </Row>
                    </Form>
                </CardBody>
            </Card >
        )
    }
}

UserHandle.defaultProps = {
    props: {},
    value: () => null,
};

UserHandle.propTypes = {
    props: PropTypes.object,
    value: PropTypes.func,
};

export default UserHandle;

